const state = {
  isSidePanelOpen: true,
  twoFAMode: false,
  stayInMyKcm: false,
};

const getters = {
  isSidePanelOpen: (state) => state.isSidePanelOpen,
  twoFAMode: (state) => state.twoFAMode,
  stayInMyKcm: (state) => state.stayInMyKcm,
};

const actions = {
  toggleSidePanel(context) {
    context.commit('toggleSidePanel');
  },
  setTwoFAMode(context, state) {
    if(state.stayInMyKcm) state.stayInMyKcm = true
    else state.stayInMyKcm = false

    if (!context.rootState.auth.twoFA) {
      context.commit('setTwoFAMode', state);
      return false
    } else if (state.state && state.url) location.href = state.url;
    else return true
  },
};

const mutations = {
  toggleSidePanel(state) {
    state.isSidePanelOpen = !state.isSidePanelOpen;
  },
  setTwoFAMode(state, data) {
    state.twoFAMode = data.state;
    state.stayInMyKcm = data.stayInMyKcm;
    if (!state.isSidePanelOpen) state.isSidePanelOpen = true;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
