<template>
  <div class="users">
    <template v-if="!loading">
      <p v-if="$route.query.search && !users.length">Няма намерени резултати за {{ $route.query.search }}</p>
      <Table v-else>
        <thead>
          <tr>
            <th>НОМЕР</th>
            <th></th>
            <th>ИМЕНА</th>
            <th>МЕЙЛ</th>
            <th>ТЕЛЕФОН</th>
            <th>ПОЗИЦИЯ</th>
            <th>СТРУКТУРНО ЗВЕНО</th>
            <th>СТАТУС</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in users" :key="`users-${e._id}`" @click="openUser(e._id)">
            <td>{{ e.number }}</td>
            <td><Avatar :user="e" :big="true" /></td>
            <td>{{ e.name }}</td>
            <td>{{ e.email }}</td>
            <td>{{ e.phone }}</td>
            <td>{{ e.position ? e.position.name : '' }}</td>
            <td>{{ e.structuralUnit ? e.structuralUnit.name : '' }}</td>
            <td>
              <span
                class="status"
                :class="`status--${e.fired ? 'inactive' : 'active'}`"
                :title="e.fired ? 'Неактивен' : 'Активен'"
              ></span>
            </td>
          </tr>
        </tbody>
      </Table>
      <Pagination :total="total" :size="size" />
    </template>
  </div>
</template>

<script>
import Table from '@/components/Table';
import Avatar from '@/components/Avatar';
import Pagination from '@/components/Pagination';
import { mapGetters } from 'vuex';
export default {
  components: {
    Table,
    Avatar,
    Pagination,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },
  data() {
    return {
      loading: false,
      size: 20,
      total: 0,
      users: [],
    };
  },
  watch: {
    $route: {
      handler: 'getUsers',
      immediate: true,
    },
  },
  methods: {
    getUsers() {
      this.loading = true;
      this.users = [];
      let url = `/users?size=${this.size}&page=${this.$route.query.page || 1}&company=${this.activeCompany}`;

      if (this.$route.query.search) {
        url += `&search=${this.$route.query.search}`;
      }

      this.$apiService
        .get(url)
        .then((res) => {
          this.users = res.data.users;
          this.total = res.data.total;
        })
        .then(() => {
          this.loading = false;
        });
    },
    openUser(id) {
      if (this.currentUser.roles.admin || this.currentUser.roles.hr) {
        this.$router.push(`/users/${id}`);
      } else if (this.currentUser.roles.supervisor) {
        this.$router.push(`/users/${id}/vacations`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.users {
  height: 100%;
  padding: 10px;
  width: 100%;
}

.table {
  tr {
    th:last-child,
    td:last-child {
      text-align: center;
      width: 1%;
    }
  }
}

.status {
  background-color: #d3d3d3;
  border-radius: 50%;
  display: inline-block;
  height: 16px;
  width: 16px;

  &.status--active {
    background-color: #019515;
  }
  &.status--inactive {
    background-color: $darkRed;
  }
}
</style>