<template>
  <div class="sidepanel__users">
    <InputSearch
      type="text"
      placeholder="Търсене на работници и служители"
      v-model="search"
      @enter="pushToRoute"
    />
    <Button
      class="button--big button--blue"
      @click="newUser"
      v-if="activeCompany === 'КЦМ 2000'"
    >
      ДОБАВИ СЛУЖИТЕЛ
    </Button>
  </div>
</template>

<script>
import InputSearch from "@/components/inputs/InputSearch";
import Button from "@/components/Button";
import { mapGetters } from "vuex";
export default {
  components: {
    InputSearch,
    Button,
  },
  computed: {
    ...mapGetters(["activeCompany"]),
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    newUser() {
      // this.$snotify.warning("Не можете да създавате нови служители");
      this.$router.push("/users/new");
    },
    pushToRoute() {
      if (this.$route.query.search !== this.search)
        this.$router.push({
          path: "/users",
          query: { search: this.search },
        });
    },
  },
  mounted() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.sidepanel__users {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.button {
  font-weight: 700;
  margin: 10px 0;
}
</style>