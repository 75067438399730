<template>
  <div class="user-vacations">
    <div v-if="twoFAMode" class="loading">
      <Loading />
    </div>
    <div v-else class="tiny__content">
      <p class="tiny__content__title">ВСИЧКИ ЕЛЕКТРОННИ ДОКУМЕНТИ НА СЛУЖИТЕЛЯ</p>
      <hr class="line" />
      <Table v-if="trdocs.length">
        <thead>
          <tr>
            <th>№</th>
            <th>ДАТА</th>
            <th>ДАТА ПРИЕМАНЕ</th>
            <th>ДОКУМЕНТ</th>
            <th>СЪЗДАДЕН ОТ</th>
            <th>ПОЛУЧАТЕЛ</th>
            <th>СТАТУС</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in trdocs" :key="d._id" @click="openDocument(d)">
            <td>
              <strong>{{
                d.finalized && d.type.type !== 'manualUpload' ? `${d.numberPrefix}-${d.number}` : '-'
              }}</strong>
            </td>
            <td>
              <p>{{ d.createdAt | formatDate }}</p>
              <p>{{ d.createdAt | formatDateHours }}</p>
            </td>
            <td>
              <template v-if="showFinalizedField(d)">
                <p>{{ d.finalizedAt | formatDate }}</p>
                <p>{{ d.finalizedAt | formatDateHours }}</p>
              </template>
            </td>
            <td>
              <strong>{{ d.type.name }}</strong>
            </td>
            <td>{{ d.createdBy ? d.createdBy.name : '' }}</td>
            <td>{{ d.receiver ? d.receiver.name : '' }}</td>
            <td class="td--right">
              <DocumentStatus v-if="d.type.type !== 'manualUpload'" :type="getStatus(d)" :d="d" />
              <div class="btns" v-else>
                <a class="icon2 download" title="Свали документа">F</a>
                <template v-if="currentUser.roles.admin || currentUser.roles.hr">
                  <a class="icon2 rename" @click.stop="renameTrdoc = d" title="Преименувай документа">G</a>
                  <a class="icon2 delete" @click.stop="deleteTrdoc(d)" title="Изтрий документа">H</a>
                </template>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <p v-else>Служителят все още няма добавени електронни трудови досиета</p>
    </div>
    <Pagination :total="total" :size="size" />

    <transition name="fade">
      <RenameTrdocModal v-if="renameTrdoc" :d="renameTrdoc" @close="closeRenameTrdoc" />
    </transition>
  </div>
</template>

<script>
import Table from '@/components/Table';
import { mapGetters } from 'vuex';
import types from '@/data/vacation-types.json';
import config from '../../../config-public.json';
import Pagination from '../../components/Pagination.vue';
import DocumentStatus from '../../components/DocumentStatus.vue';
import Loading from '../../components/Loading.vue';
import RenameTrdocModal from '../../components/RenameTrdocModal.vue';
export default {
  components: {
    Table,
    Pagination,
    DocumentStatus,
    Loading,
    RenameTrdocModal,
  },
  computed: {
    ...mapGetters(['currentUser', 'twoFAMode']),
  },

  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
  watch: {
    $route: {
      handler: 'getTrdocs',
      immediate: true,
    },
  },
  data() {
    return {
      config,
      types,
      trdocs: [],
      size: 30,
      total: 0,
      renameTrdoc: null,
    };
  },
  methods: {
    showFinalizedField(d) {
      if (d.finalizedAt && ['salaryAgreement', 'positionAgreement', 'manualUpload'].includes(d.type.type)) {
        return true;
      } else return false;
    },
    openDocument(d) {
      if (d.type.type === 'manualUpload') {
        window.open(`${config.trdosieUrl}/api/trdocs/file/${d._id}#toolbar=0&navpanes=0`);
      } else {
        window.open(`${config.trdosieUrl}?openDocument=${d._id}`);
      }
    },
    getStatus(d) {
      if (d.annulled) return 'annulled';
      if (d.finalized)
        return d.canBeUsedAsParent === false
          ? d.type.type === 'scoreCardStudent'
            ? 'used-as-parent-card'
            : 'used-as-parent'
          : 'approved';
      if (d.form.approved === false || d.form.approvedByRecieverTwo === false || d.form.approvedByReciever === false)
        return 'not-approved';
      else return 'waiting';
    },
    closeRenameTrdoc(update) {
      if (update) {
        this.getTrdocs();
      }
      this.renameTrdoc = null;
    },
    deleteTrdoc(d) {
      if (confirm('Сигурни ли сте, че искате да изтриете този документ?')) {
        this.$apiService
          .delete(`/users/${this.$route.params.id}/trdoc/${d._id}`)
          .then(() => {
            this.getTrdocs();
          })
          .catch((err) => console.log(err));
      }
    },
    getTrdocs() {
      this.trdocs = [];
      let url = `/users/${this.$route.params.id}/trdoc?size=${this.size}&page=${this.$route.query.page || 1}`;

      this.$apiService
        .get(url)
        .then((res) => {
          let ownAndSupervisor =
            (this.currentUser.roles.supervisor || this.$route.params.id === this.currentUser._id) &&
            !this.currentUser.roles.admin &&
            !this.currentUser.roles.hr;

          if (this.currentUser.roles.admin || this.currentUser.roles.hr || ownAndSupervisor) {
            this.trdocs = res.data.trdocs;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.message) {
            this.$snotify.error(err.response.data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.table-wrapper {
  table {
    th,
    td {
      padding: 14px 5px;

      .btns {
        display: inline-flex;
        gap: 4px;
        .icon2 {
          color: #004d94;
          // font-weight: bold;
          font-size: 20px;
          @include hover();

          &:hover {
            opacity: 1;
          }
          &.download:hover {
            color: #199139;
          }
          &.rename:hover {
            color: #056aae;
          }
          &.delete:hover {
            color: #920606;
          }
        }
      }
    }
  }
}
</style>
