<template>
  <div class="user-personal-data" v-if="currentUser._id">
    <div class="tiny__content">
      <p class="tiny__content__title">ЛИЧНИ ДАННИ</p>
      <hr class="line" />
      <div class="row">
        <Input
          label="СЛУЖЕБЕН НОМЕР"
          placeholder="Служебен номер"
          :disabled="$route.name !== 'newUser'"
          :invalid="invalidNumber"
          autocomplete="chrome-off"
          @input="invalidNumber = false"
          v-model="user.number"
        />
        <Input
          label="ПАРОЛА"
          placeholder="Парола"
          type="password"
          :invalid="invalidPassword"
          autocomplete="new-password"
          @input="invalidPassword = false"
          v-model="user.password"
          :disabled="!currentUser.roles.admin && !currentUser.roles.hr"
        />
      </div>
      <div class="row">
        <Input
          label="ИМЕНА"
          placeholder="Имена"
          v-model="user.name"
          :disabled="!currentUser.roles.admin && !currentUser.roles.hr"
        />
        <Input
          label="График"
          placeholder="График"
          :value="user.schedule"
          :disabled="true"
          v-if="activeCompany === 'КЦМ АД'"
        />
      </div>
      <div class="row">
        <Input
          label="МЕЙЛ"
          placeholder="Мейл"
          v-model="user.email"
          :disabled="!currentUser.roles.admin && !currentUser.roles.hr"
        />
        <Input
          label="ТЕЛЕФОН"
          placeholder="Телефон"
          v-model="user.phone"
          :disabled="!currentUser.roles.admin && !currentUser.roles.hr"
        />
      </div>
      <div class="row">
        <Input
          label="ПОЗИЦИЯ"
          placeholder="Позиция"
          :value="user.position ? user.position.name : ''"
          :disabled="true"
          v-if="user._id"
        />
        <Input label="ПОЗИЦИЯ" placeholder="Позиция" v-model="user.position" v-else />
        <Input
          label="ОРГАНИЗАЦИОННА ЕДИНИЦА"
          placeholder="Организационна единица"
          :value="user.structuralUnit ? user.structuralUnit.name : ''"
          :disabled="true"
          v-if="user._id"
        />
        <Input
          label="ОРГАНИЗАЦИОННА ЕДИНИЦА"
          placeholder="Организационна единица"
          v-model="user.structuralUnit"
          v-else
        />
      </div>
      <div class="row">
        <Input
          type='dropdown'
          label="ПЪРВО НИВО ОДОБРЯВАЩ"
          placeholder="Първо ниво одобряващ"
          v-model="user.supervisorFirst"
          prop="name"
          :identifier="user.supervisorFirst ? '_id' : false"
          :disabled="!currentUser.roles.admin && !currentUser.roles.hr"
          :list="supervisors"
          @focus="getSupervisors"
          @search="getSupervisors"
        />
        <Input
          type='dropdown'
          label="ВТОРО НИВО ОДОБРЯВАЩ"
          placeholder="Второ ниво одобряващ"
          v-model="user.supervisorSecond"
          prop="name"
          :identifier="user.supervisorSecond ? '_id' : false"
          :disabled="!currentUser.roles.admin && !currentUser.roles.hr"
          :list="supervisors"
          @focus="getSupervisors"
          @search="getSupervisors"
        />
      </div>
      <div class="row">
        <Input
          v-if="currentUser.roles.admin || currentUser.roles.hr"
          accept=".jpg, .png, .gif"
          label="Аватар"
          type="file"
          :placeholder="'placeholder'"
          :multiple="false"
          :files="avatar"
          @files-selected="handleFile($event)"
        />
      </div>
      <p v-if="user.avatar && !removeAvatar" @click="(removeAvatar = true), save" class="remove--avatar">
        Премахни аватара
      </p>
      <!-- <p class="tiny__content__title">
        ДАННИ ЗА ЛИЧНИТЕ ПРЕДПАЗНИ СРЕДСТВА
      </p>
      <hr class="line" />
      <div class="row">
        <Input
          label="РАЗМЕР ГОРНО ОБЛЕКЛО"
          placeholder="Размер облекло"
          v-model="user.sizeUpper"
          :disabled="true"
        />
        <Input
          label="РАЗМЕР ОБУВКИ"
          placeholder="Размер обувки"
          v-model="user.sizeLower"
          :disabled="true"
        />
      </div>
      <div class="row">
        <Input
          label="РАЗМЕР ДОЛНО ОБЛЕКЛО"
          placeholder="Размер облекло"
          v-model="user.sizeShoes"
          :disabled="true"
        />
        <Input
          label="ПОЛ"
          placeholder="Пол"
          v-model="user.sex"
          :disabled="true"
        />
      </div> -->
      <hr class="line" />
      <div class="tiny__content__buttons">
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button @click="cancel">Откажи</Button>
        <Button class="button--blue" @click="save" :disabled="loading">Запиши</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Input from '@/components/inputs/Input';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
export default {
  components: {
    Input,
    Button,
    Loading,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {
      invalidNumber: false,
      invalidPassword: false,
      loading: false,
      removeAvatar: false,
      success: undefined,
      user: {
        supervisorFirst: null,
        supervisorSecond: null,
        position: '',
        structuralUnit: null,
      },
      supervisors: [],
      avatar: null,
    };
  },
  methods: {
    handleFile(f) {
      if (this.validateFile(f)) this.avatar = f;
    },
    validateFile(f) {
      if (f) {
        if (!f.name.toLowerCase().match(/\.(jpg|png|gif)$/)) {
          this.$snotify.error('Може да качвате само (JPG, PNG, GIF) изображения (.jpg, .png, .gif).');
          return false;
        }

        const maxAllowedMB = 2097152; // 2 MB
        if (f.size > maxAllowedMB) {
          this.$snotify.error('Размера на аватара не може да надвишава повече от 2 MB!');
          return false;
        }
        return true;
      }
    },
    cancel() {
      this.$router.push('/users');
    },
    getSupervisors(search) {
      this.supervisors = [];
      let url = `/users/supervisors?companies=${this.activeCompany}`;

      if (search) {
        url += `&search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.supervisors = res.data;
      });
    },
    getUser() {
      this.$apiService
        .get(`/users/${this.$route.params.id}`)
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          this.$snotify.error(err.response.data.message);
          this.$router.push('/users');
        });
    },
    isValid() {
      if (!this.user.number) {
        this.invalidNumber = true;
      }
      if (!this.user.password) {
        this.invalidPassword = true;
      }

      return !(this.invalidNumber || this.invalidPassword);
    },
    create() {
      this.loading = true;

      this.user.company = this.activeCompany;
      this.user.companies = [this.activeCompany];
      let id = '';

      this.$apiService
        .post('/auth/register', this.user)
        .then((res) => {
          id = res.data.user;
          this.success = true;
        })
        .catch((err) => {
          this.success = false;
          this.$snotify.error(err.response.data.message);
          console.log(err);
        })
        .then(() => {
          setTimeout(() => {
            if (this.success && id) {
              this.$router.push(`/users/${id}`);
            }

            this.loading = false;
            this.success = undefined;
          }, 1000);
        });
    },
    edit() {
      this.loading = true;
      let fd = new FormData();
      this.user.removeAvatar = this.removeAvatar;
      fd.append('user', JSON.stringify(this.user));

      if (this.avatar) {
        fd.append('avatar', this.avatar);
      }

      this.$apiService
        .put(`/users/personal-data/${this.$route.params.id}`, fd)
        .then(() => {
          this.success = true;
        })
        .catch((err) => {
          this.success = false;
          console.log(err);
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.success = undefined;
          }, 1000);
        });
    },
    save() {
      if (this.$route.name === 'userPersonalData') {
        this.edit();
      } else {
        if (this.isValid()) {
          this.create();
        }
      }
    },
  },
  mounted() {
    if (this.$route.name === 'userPersonalData') {
      this.getUser();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@media screen and (min-width: $s) {
  .row {
    display: flex;
    margin: 1rem 0;
    .input-wrapper {
      width: 100%;
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .row {
    .input-wrapper {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
</style>
