import Users from '@/views/users/Users.vue';
import UserPersonalData from '@/views/users/UserPersonalData.vue';
import UserPermissions from '@/views/users/UserPermissions.vue';
import UserVacations from '@/views/users/UserVacations.vue';
import UserVacationsHourly from '@/views/users/UserVacationsHourly.vue';
import UserTrdocs from '@/views/users/UserTrdocs.vue';
import UserReferences from '@/views/users/UserReferences.vue';
import UserLPS from '@/views/users/UserLPS.vue';
import UserActivities from '@/views/users/UserActivities.vue';

export default [
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin', 'hr', 'supervisor']
    }
  },
  {
    path: '/users/new',
    name: 'newUser',
    component: UserPersonalData,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin', 'hr', 'supervisor']
    }
  },
  {
    path: '/users/:id',
    name: 'userPersonalData',
    component: UserPersonalData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id/permissions',
    name: 'userPermissions',
    component: UserPermissions,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    }
  },
  {
    path: '/users/:id/vacations',
    name: 'userVacations',
    component: UserVacations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id/vacations-hourly',
    name: 'userVacationsHourly',
    component: UserVacationsHourly,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id/trdoc',
    name: 'userTrdoc',
    component: UserTrdocs,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id/references',
    name: 'userReferences',
    component: UserReferences,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin', 'hr', 'supervisor']
    }
  },
  {
    path: '/users/:id/lps',
    name: 'userLPS',
    component: UserLPS,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users/:id/activities',
    name: 'userActivities',
    component: UserActivities,
    meta: {
      requiresAuth: true
    }
  }
];
