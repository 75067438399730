var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidepanel no-print",class:{
    'sidepanel--in': _vm.isAuthenticated,
    'sidepanel--hidden':
      !_vm.isSidePanelOpen || _vm.isAuthenticated === undefined || (_vm.$route.name === 'Home' && _vm.isAuthenticated && !_vm.twoFAMode),
  }},[_c('transition',{attrs:{"name":"sidepanel-toggle"}},[(_vm.isAuthenticated && (_vm.$route.name !== 'Home' || _vm.twoFAMode))?_c('div',{staticClass:"sidepanel__toggle",on:{"click":function($event){return _vm.$store.dispatch('toggleSidePanel')}}},[_c('img',{attrs:{"src":require("@/assets/img/sidepanel/trapezoid.svg"),"alt":"Trapezoid for sidepanel opening/closing"}}),_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/img/sidepanel/arrow.svg"),"alt":"Arrow"}}),_c('Tooltip',{attrs:{"tooltip":_vm.getTooltip}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isAuthenticated === false)?_c('Login',{key:"sidepanel__login"}):(_vm.twoFAMode)?_c('TwoFA',{key:"sidepanel__2fa"}):(_vm.$route.name === 'users')?_c('Users',{key:"sidepanel__users"}):(
        _vm.$route.name === 'newUser' ||
        _vm.$route.name === 'userPersonalData' ||
        _vm.$route.name === 'userPermissions' ||
        _vm.$route.name === 'userVacations' ||
        _vm.$route.name === 'userVacationsHourly' ||
        _vm.$route.name === 'userTrdoc' ||
        _vm.$route.name === 'userReferences' ||
        _vm.$route.name === 'userLPS' ||
        _vm.$route.name === 'userActivities'
      )?_c('User',{key:"sidepanel__user"}):_c('div',{key:"default-state",staticClass:"portal-animation"},[_c('img',{attrs:{"src":require("@/assets/img/sidepanel/portal-animation.svg")}})]),_vm._v(" "+_vm._s(_vm.$route.name)+" ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }