<template>
  <div
    class="sidepanel no-print"
    :class="{
      'sidepanel--in': isAuthenticated,
      'sidepanel--hidden':
        !isSidePanelOpen || isAuthenticated === undefined || ($route.name === 'Home' && isAuthenticated && !twoFAMode),
    }"
  >
    <transition name="sidepanel-toggle">
      <div
        class="sidepanel__toggle"
        @click="$store.dispatch('toggleSidePanel')"
        v-if="isAuthenticated && ($route.name !== 'Home' || twoFAMode)"
      >
        <img src="@/assets/img/sidepanel/trapezoid.svg" alt="Trapezoid for sidepanel opening/closing" />
        <img src="@/assets/img/sidepanel/arrow.svg" class="arrow" alt="Arrow" />
        <Tooltip :tooltip="getTooltip" />
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <Login key="sidepanel__login" v-if="isAuthenticated === false" />
      <TwoFA key="sidepanel__2fa" v-else-if="twoFAMode" />
      <Users key="sidepanel__users" v-else-if="$route.name === 'users'" />
      <User
        key="sidepanel__user"
        v-else-if="
          $route.name === 'newUser' ||
          $route.name === 'userPersonalData' ||
          $route.name === 'userPermissions' ||
          $route.name === 'userVacations' ||
          $route.name === 'userVacationsHourly' ||
          $route.name === 'userTrdoc' ||
          $route.name === 'userReferences' ||
          $route.name === 'userLPS' ||
          $route.name === 'userActivities'
        "
      />
      <div class="portal-animation" v-else key="default-state">
        <img src="@/assets/img/sidepanel/portal-animation.svg" />
      </div>
      {{ $route.name }}
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Login from './sidepanel/Login';
import TwoFA from './sidepanel/2FA';
import Users from './sidepanel/Users';
import User from './sidepanel/User';
import Tooltip from './Tooltip';
export default {
  components: {
    Login,
    TwoFA,
    Users,
    User,
    Tooltip,
  },
  computed: {
    ...mapGetters(['isSidePanelOpen', 'isAuthenticated', 'currentUser', 'twoFAMode']),
    getTooltip() {
      const tooltips = {
        vacations: 'Кликни тук за повече опции и създаване на нов отпуск.',
        vacationsHourly: 'Кликни тук за повече опции и създаване на ново отсъствие.',
        scheduleGroup: 'Кликни тук за избор между Общ график и График смяна.',
        lps: 'Кликни тук за съобщение за ЛПС (ще се промени).',
        users: 'Кликни тук за търсене на служители',
        settingsHolidays: 'Кликни тук за различните настройки.',
        userPersonalData: 'Кликни тук за различни настройки на профила.',
      };

      // if (
      //   this.$route.name === 'userPersonalData' &&
      //   this.currentUser._id !== this.$route.params.id
      // ) {
      //   return;
      // }

      return tooltips[this.$route.name];
    },
  },
  watch: {
    isAuthenticated(to) {
      if (!to && this.$route.name !== 'help') this.$store.dispatch('setTwoFAMode', { state: false });
      if (!to && window.innerWidth <= 768 && !this.isSidePanelOpen) {
        // if (to && window.innerWidth <= 1280) {
        this.$store.dispatch('toggleSidePanel');
      }
    },
    '$route.name'() {
      if (this.isSidePanelOpen && window.innerWidth <= 768 && this.isAuthenticated) {
        // if (this.isSidePanelOpen && window.innerWidth <= 1280) {
        this.$store.dispatch('toggleSidePanel');
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.sidepanel {
  background-color: $mainColor;
  border: 2px solid #fff;
  border-radius: 10px;
  height: calc(100% - 10vh);
  left: 50%;
  position: fixed;
  top: 5vh;
  width: $sidepanel;
  z-index: 11;
  @include transform(translateX(-50%));
  @include transition($transition);

  &.sidepanel--in {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: calc(100% - 8px - #{$nav});
    left: 4px;
    top: 4px;
    width: $sidepanel;
    @include transform(initial);
  }

  &.sidepanel--hidden {
    border: 2px solid $mainColor;
    // opacity: 0;
    @include transform(translateX(-100%));
    &:not(.sidepanel--in) {
      left: 0;
    }
  }

  > div:not(.sidepanel__toggle) {
    max-height: 100%;
    overflow: auto;
    @include scrollbar(3px, $mainColor);
  }
}

.sidepanel__toggle,
.logout__toggle {
  align-items: center;
  bottom: 50px;
  // clip-path: polygon(0 0, 100% 20%, 100% 80%, 0% 100%);
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 1px;
  @include transform(translateX(100%));
  @include noselect();
  img {
    width: 100%;
  }
  .arrow {
    position: absolute;
    width: 60%;
    @include transition($transition);
  }
}

.sidepanel--hidden {
  .sidepanel__toggle {
    .arrow {
      @include transform(scaleX(-1));
    }
  }
}

.logout__toggle {
  display: none;
  .icon {
    color: $darkYellow;
    font-size: 1.3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    @include transform(translateX(-50%) translateY(-50%));
  }
}

.sidepanel__texts {
  color: #fff;
  font-size: $px11;
  margin: 3rem 0;
  width: 330px;
  .title {
    color: $lightAccent;
    font-size: $px25;
    font-weight: 500;
  }
}

.sidepanel__link {
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.1);
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  height: 40px;
  margin-bottom: 4px;
  min-height: 40px;
  padding: 0 10px;
  position: relative;
  width: 100%;
  @include transition($transition);

  &::after {
    content: 'V';
    color: $darkBlue;
    font-family: 'icons';
    font-weight: 400;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    @include transform(translateY(-50%));
    @include transition($transition);
  }

  &.router-link-exact-active {
    background-color: #e0eaf3;
    color: $darkBlue;
    &::after {
      opacity: 1;
      @include transform(translateY(-50%) translateX(4px));
    }
  }
}

.portal-animation {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  img {
    width: 86%;
  }
}

.sidepanel-toggle-enter-active,
.sidepanel-toggle-leave-active {
  @include transition($transition);
}

.sidepanel-toggle-enter,
.sidepanel-toggle-leave-to {
  opacity: 0;
  @include transform(translateX(-100%));
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .sidepanel {
    &.sidepanel--in {
      height: calc(100% - 8px - #{$nav-s});
    }
  }
  .sidepanel__toggle {
    bottom: 15px;
  }
  .logout__toggle {
    bottom: 70px;
    display: flex;
  }
}

@media screen and (max-width: $xxs) {
  .sidepanel {
    width: $sidepanel-xxs;
    &.sidepanel--in {
      width: $sidepanel-xxs;
    }
  }
  .sidepanel__texts {
    width: 260px;
  }
  .sidepanel__link {
    font-size: $px10;
  }
}
</style>
