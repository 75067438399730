<template>
  <div class="user-vacations" :class="{ 'user-vacations--2000': activeCompany === 'КЦМ 2000' }">
    <div class="tiny__content">
      <p class="tiny__content__title">ОСТАВАЩИ ДНИ ОТПУСК</p>
      <hr class="line" />
      <!--  -->
      <div class="user-vacations__count">
        <p>
          <span class="icon">C</span>
          ОСТАВАЩИ ДНИ ПЛАТЕН ГОДИШЕН ОТПУСК
        </p>
        <p v-if="activeCompany === 'КЦМ 2000'">
          <template v-if="!(typeof editing.paid === 'object')">
            <span class="icon edit" key="icon-11" @click="save('paid')"> W </span>
            <span class="icon edit" key="icon-12" @click="editing.paid = null"> g </span>
            <input type="number" v-model="editing.paid" @input="check('paid')" v-focus />
          </template>
          <template v-else>
            <span
              class="icon edit"
              @click="editing.paid = remaining.paid"
              v-if="currentUser.roles.admin || currentUser.roles.hr"
            >
              t
            </span>
            <span class="number" key="number-1">{{ remaining.paid || 0 }}</span>
          </template>
        </p>
        <p v-else>{{ remaining.paid || 0 }}</p>
      </div>
      <!--  -->
      <div class="user-vacations__count user-vacations__count--union" v-if="activeCompany !== 'КЦМ 2000'">
        <p>
          <span class="icon">C</span>
          ПРАВО НА ДНИ ПЛАТЕН ОТПУСК СИНДИКАЛНИ ДЕЙЦИ
        </p>
        <p>{{ remaining['paid-union'] || 0 }}</p>
      </div>
      <!--  -->
      <div class="user-vacations__count user-vacations__count--paid-student">
        <p>
          <span class="icon">C</span>
          ПРАВО НА ДНИ ПЛАТЕН УЧЕНИЧЕСКИ ОТПУСК ЗА ОБУЧЕНИЕ
        </p>
        <p v-if="activeCompany === 'КЦМ 2000'">
          <template v-if="!(typeof editing['paid-student'] === 'object')">
            <span class="icon edit" key="icon-21" @click="save('paid-student')"> W </span>
            <span class="icon edit" key="icon-22" @click="editing['paid-student'] = null"> g </span>
            <input type="number" v-model="editing['paid-student']" @input="check('paid-student')" v-focus />
          </template>
          <template v-else>
            <span
              class="icon edit"
              @click="editing['paid-student'] = remaining['paid-student']"
              v-if="currentUser.roles.admin || currentUser.roles.hr"
            >
              t
            </span>
            <span class="number" key="number-2">
              {{ remaining['paid-student'] || 0 }}
            </span>
          </template>
        </p>
        <p v-else>{{ remaining['paid-student'] || 0 }}</p>
      </div>
      <!--  -->
      <div class="user-vacations__count user-vacations__count--paid-graduation">
        <p>
          <span class="icon">C</span>
          ПРАВО НА ДНИ ПЛАТЕН УЧЕНИЧЕСКИ ОТПУСК ЗА ДИПЛОМИРАНЕ
        </p>
        <p v-if="activeCompany === 'КЦМ 2000'">
          <template v-if="!(typeof editing['paid-graduation'] === 'object')">
            <span class="icon edit" key="icon-31" @click="save('paid-graduation')">W</span>
            <span class="icon edit" key="icon-32" @click="editing['paid-graduation'] = null">g</span>
            <input type="number" v-model="editing['paid-graduation']" @input="check('paid-graduation')" v-focus />
          </template>
          <template v-else>
            <span
              class="icon edit"
              @click="editing['paid-graduation'] = remaining['paid-graduation']"
              v-if="currentUser.roles.admin || currentUser.roles.hr"
            >
              t
            </span>
            <span class="number" key="number-3">
              {{ remaining['paid-graduation'] || 0 }}
            </span>
          </template>
        </p>
        <p v-else>{{ remaining['paid-graduation'] || 0 }}</p>
      </div>
      <!--  -->
      <div class="user-vacations__count user-vacations__count--unpaid">
        <p>
          <span class="icon">C</span>
          ПРАВО НА ДНИ НЕПЛАТЕН ОТПУСК
        </p>
        <p v-if="activeCompany === 'КЦМ 2000'">
          <template v-if="!(typeof editing.unpaid === 'object')">
            <span class="icon edit" key="icon-41" @click="save('unpaid')">W</span>
            <span class="icon edit" key="icon-42" @click="editing.unpaid = null">g</span>
            <input type="number" v-model="editing.unpaid" @input="check('unpaid')" v-focus />
          </template>
          <template v-else>
            <span
              class="icon edit"
              @click="editing.unpaid = remaining.unpaid"
              v-if="currentUser.roles.admin || currentUser.roles.hr"
            >
              t
            </span>
            <span class="number" key="number-4">
              {{ remaining.unpaid || 0 }}
            </span>
          </template>
        </p>
        <p v-else>{{ remaining.unpaid || 0 }}</p>
      </div>

      <p class="tiny__content__title tiny__content__title--dropwdown">
        ВСИЧКИ ИЗПОЛЗВАНИ ОТПУСКИ ОТ СЛУЖИТЕЛЯ
        <Input v-model="selectedYear" :list="userYears" placeholder="Година" @input="getVacations()" />
      </p>
      <hr class="line" />
      <Table v-if="vacations.length">
        <thead>
          <tr>
            <th>№</th>
            <th>ДАТА</th>
            <th>ВИД ОТПУСК</th>
            <th>ПЕРИОД/РАЗМЕР</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="v in vacations" :key="v._id" @click="openVacation(v._id)">
            <td>{{ v.number }}</td>
            <td>{{ v.dateOfSigning | formatDate }}</td>
            <td>{{ types[v.type] }}</td>
            <td>
              {{ v.dateFrom | formatDate }} - {{ v.dateTo | formatDate }}

              <template v-if="v.type === 'paid-father'"
                >({{ v.totalDays.length }} {{ v.totalDays.length === 1 ? 'ден' : 'дни' }})</template
              >
              <template v-else>({{ v.workingDays.length }} {{ v.workingDays.length === 1 ? 'ден' : 'дни' }})</template>
            </td>
          </tr>
        </tbody>
      </Table>
      <p v-else>Служителят все още няма добавени отпуски за {{ selectedYear }} година</p>
    </div>
    <Pagination :total="total" :size="size" />
  </div>
</template>

<script>
import Table from '@/components/Table';
import { mapGetters } from 'vuex';
import types from '@/data/vacation-types.json';
import config from '../../../config-public.json';
import Pagination from '../../components/Pagination.vue';
import Input from '../../components/inputs/Input.vue';
import { getYear, lastDayOfYear, startOfYear } from 'date-fns';
export default {
  components: {
    Table,
    Pagination,
    Input,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },

  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
  watch: {
    $route: {
      handler: 'getYears',
      immediate: true,
    },
  },
  data() {
    return {
      config,
      types,
      editing: {
        paid: null,
        'paid-union': null,
        'paid-student': null,
        'paid-graduation': null,
        unpaid: null,
      },
      remaining: {},
      vacations: [],
      userYears: [],
      selectedYear: '',
      size: 30,
      total: 0,
    };
  },
  methods: {
    check(t) {
      if (+this.editing[t] < 0) this.editing[t] = 0;
      else if (+this.editing[t] > 999) this.editing[t] = 999;
      else this.editing[t] = +this.editing[t];
    },
    getPaidVacation() {
      this.$apiService.get(`/users/${this.$route.params.id}/vacation-days`).then((res) => {
        this.remaining = res.data || {};
      });
    },
    getVacations() {
      this.vacations = [];
      let url = `/users/${this.$route.params.id}/vacations?size=${this.size}&page=${this.$route.query.page || 1}`;
      if (this.selectedYear) {
        let start = lastDayOfYear(new Date(this.selectedYear, 1, 1));
        let end = startOfYear(new Date(this.selectedYear, 1, 1));

        start.setHours(12, 0, 0, 0);
        end.setHours(12, 0, 0, 0);
        url += `&dateFrom=${start}&dateTo=${end}`;
      }

      this.$apiService.get(url).then((res) => {
        this.vacations = res.data.vacations;
        this.total = res.data.total;
      });
    },
    getYears() {
      this.userYears = [];
      let url = `/users/${this.$route.params.id}/getYears/vacations`;

      this.$apiService.get(url).then((res) => {
        this.userYears = res.data;
      });
    },
    openVacation(v) {
      location.href = `${config.otpuskiUrl}/vacations/${v}`;
    },
    save(t) {
      this.$apiService
        .put(`/users/user-vacations/${this.$route.params.id}`, {
          type: [t],
          remaining: this.editing[t],
        })
        .then(() => {
          this.$snotify.success('Успешно записано');
          this.remaining[t] = this.editing[t];
          this.editing[t] = null;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.selectedYear = getYear(new Date());
    this.getVacations();
    this.getPaidVacation();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.user-vacations__count {
  align-items: center;
  background-color: $lightBlue;
  border-radius: 10px;
  color: #fff;
  display: flex;
  height: 66px;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem;
  padding-right: 1.75rem;
  &.user-vacations__count--union {
    background-color: #26c6da;
    margin-top: 0.5rem;
  }
  &.user-vacations__count--paid-student {
    background-color: #009458;
    margin-top: 0.5rem;
  }
  &.user-vacations__count--paid-graduation {
    background-color: #76aa1b;
    margin-top: 0.5rem;
  }
  &.user-vacations__count--unpaid {
    background-color: #ad1457;
    margin-top: 0.5rem;
  }
  p {
    align-items: center;
    display: flex;
    font-weight: 700;
    .icon {
      font-size: $px18;
      font-weight: 400;
      margin-right: 1rem;
      &.edit {
        cursor: pointer;
        font-size: 2rem;
        margin-right: 0.5rem;
        opacity: 0.5;
        @include transition($transition);
        & + input,
        & + .number {
          margin-left: 0.5rem;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    &:last-child {
      font-size: 42px;
      font-weight: 500;
    }
    input {
      background-color: transparent;
      border: 2px dashed #fff;
      border-radius: 10px;
      color: #fff;
      font-size: 42px;
      outline: none;
      text-align: center;
      width: 90px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
}
.user-vacations--2000 {
  .user-vacations__count {
    background-color: #35aadf;
    &.user-vacations__count--paid-student {
      background-color: #76aa1b;
    }
    &.user-vacations__count--paid-graduation {
      background-color: #76aa1b;
    }
    &.user-vacations__count--unpaid {
      background-color: #a50101;
    }
  }
}
</style>
