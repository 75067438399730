<template>
  <div>
    <div class="nav-header" :class="{ 'nav-header--in': isAuthenticated }">
      <router-link to="/" class="nav__logo">
        <transition name="appear" appear>
          <img src="@/assets/img/logo.svg" alt="my.kcm" />
        </transition>
      </router-link>
      <transition name="fade" appear>
        <div class="nav__user" v-if="currentUser._id" @click="userModal = true">
          <div class="nav__user-texts">
            <div class="timer" v-if="showTimer" @click.stop>:{{ timer }}</div>
          </div>
          <Avatar :user="currentUser" />
        </div>
      </transition>
    </div>
    <div class="nav no-print" :class="{ 'nav--in': isAuthenticated }">
      <router-link to="/" class="nav__logo">
        <transition name="appear" appear>
          <img src="@/assets/img/logo.svg" alt="my.kcm" />
        </transition>
      </router-link>
      <div class="nav__button-group nav__button-group--for-mobile">
        <template v-if="isAuthenticated">
          <router-link to="/" class="nav__button nav__button--width">
            <span class="icon">
              <span>9</span>
              <span class="nav__button__overlay">!</span>
            </span>
            <span>НАЧАЛО</span>
          </router-link>
          <a
            :href="`${config.otpuskiUrl}/schedule`"
            class="nav__button nav__button--width"
            v-if="activeCompany === 'КЦМ АД'"
          >
            <span class="icon">
              <span>C</span>
              <span class="nav__button__overlay">L</span>
            </span>
            <span>ГРАФИК</span>
          </a>
          <a :href="`${config.otpuskiUrl}/vacations`" class="nav__button nav__button--width">
            <span class="icon">
              <span>Q</span>
              <span class="nav__button__overlay">P</span>
            </span>
            <span>ОТПУСКИ</span>
          </a>
          <a :href="`${config.otpuskiUrl}/vacations-hourly`" class="nav__button nav__button--width">
            <span class="icon">
              <span>q</span>
              <span class="nav__button__overlay">p</span>
            </span>
            <span>ОТСЪСТВИЯ</span>
          </a>
          <a @click="openTrdosie()" class="nav__button nav__button--width" v-if="activeCompany === 'КЦМ АД'">
            <span class="icon">
              <span>7</span>
              <span class="nav__button__overlay">8</span>
            </span>
            <span>ДОСИЕТА</span>
          </a>
          <router-link
            to="/users"
            class="nav__button nav__button--width"
            v-if="currentUser.roles.admin || currentUser.roles.hr || currentUser.roles.supervisor"
          >
            <span class="icon">
              <span>O</span>
              <span class="nav__button__overlay">N</span>
            </span>
            <span>СЛУЖИТЕЛИ</span>
          </router-link>
        </template>
      </div>
      <div class="nav__button-group" @click="profile">
        <div class="nav__user">
          <template v-if="currentUser._id">
            <div class="nav__user-texts">
              <div class="timer" v-if="showTimer" @click.stop>:{{ timer }}</div>
              <p>{{ currentUser.name }}</p>
              <p>{{ currentUser.company }}</p>
            </div>
            <Avatar :user="currentUser" />
            <a class="nav__user-help" :href="`/help`">
              <span class="icon">k</span>
            </a>
            <div class="nav__user-logout" @click.stop="logout">
              <span class="icon">X</span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <transition name="fade">
      <UserMobileModal v-if="userModal && isAuthenticated" @close="userModal = false" />
    </transition>
    <transition name="appear" appear>
      <div class="link-help--wrapper" v-if="$route.name === 'Home'">
        <router-link class="link-help" to="/help">
          <span class="icon">k</span>
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserMobileModal from './UserMobileModal';
import Avatar from './Avatar.vue';
import config from '../../config-public.json';
export default {
  components: {
    UserMobileModal,
    Avatar,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser', 'activeCompany']),
    showTimer() {
      if (!this.currentUser._id) {
        return false;
      } else {
        return (
          this.currentUser.roles.employee &&
          !this.currentUser.roles.hr &&
          !this.currentUser.roles.supervisor &&
          !this.currentUser.roles.admin
        );
      }
    },
  },
  data() {
    return {
      config,
      interval: null,
      timer: 60,
      count: 0,
      userModal: false,
    };
  },
  watch: {
    isAuthenticated(to) {
      this.userModal = false;
      if (to) {
        if (this.showTimer) {
          this.startTimer();
        }
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        clearInterval(this.interval);
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
      });
    },
    profile() {
      this.$router.push(`/users/${this.currentUser._id}`);
    },
    startTimer() {
      this.interval = setInterval(() => {
        if (!this.timer) {
          clearInterval(this.interval);
          this.timer = 60;
          this.logout();
        } else {
          this.timer -= 1;
          this.count += 1;
        }

        // refresh token every 10 seconds because it's valid only 60 seconds
        if (!(this.count % 10)) {
          this.$store.dispatch('checkAuth');
        }
      }, 1000);
    },
    updateTimer() {
      if (this.interval && this.showTimer) {
        this.timer = 60;
      }
    },
    goToWaitingList() {
      this.$router.push('/');
    },
    openTrdosie() {
      this.$store.dispatch('setTwoFAMode', { state: true, url: config.trdosieUrl });
    },
  },
  mounted() {
    document.addEventListener('click', this.updateTimer);
    document.addEventListener('mousemove', this.updateTimer);
    document.addEventListener('keydown', this.updateTimer);
    document.addEventListener('touchstart', this.updateTimer);
    document.addEventListener('touchend', this.updateTimer);
    document.addEventListener('touchcancel', this.updateTimer);
    document.addEventListener('touchmove', this.updateTimer);

    this.$router.onReady(() => {
      if (this.$route.query.userModal) this.userModal = true;
    });
  },
  destroyed() {
    document.removeEventListener('click', this.updateTimer);
    document.removeEventListener('mousemove', this.updateTimer);
    document.removeEventListener('keydown', this.updateTimer);
    document.removeEventListener('touchstart', this.updateTimer);
    document.removeEventListener('touchend', this.updateTimer);
    document.removeEventListener('touchcancel', this.updateTimer);
    document.removeEventListener('touchmove', this.updateTimer);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.nav-header {
  display: none;
}
.nav {
  align-items: center;
  background-color: $mainColor;
  bottom: 0;
  color: #fff;
  display: flex;
  height: 0px;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 0 4px;
  position: fixed;
  width: 100%;
  @include transition($transition);
  &.nav--in {
    height: $nav;
  }
}

.nav__logo {
  img {
    display: block;
    opacity: 0;
    width: 91px;
    @include transition($transition);
    @include transitionDelay($duration);
    @include transform(scale(0));
  }
}

.nav--in {
  .nav__logo {
    img {
      opacity: 1;
      @include transform(scale(1));
    }
  }
}
.nav__button-group {
  display: flex;
  height: 100%;
  position: relative;
}

.nav__button {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-size: $px11;
  height: 100%;
  justify-content: center;
  margin: 0 0.25rem;
  padding: 0 1rem 5px;
  position: relative;
  @include noselect();
  &.nav__button--width {
    min-width: 86px;
  }
  &.nav__button--profile-mobile {
    display: none;
  }

  > span {
    &:not(.icon) {
      @include transition($transition);
    }
    &.icon {
      font-size: 2rem;
      margin-bottom: 0.25rem;
      position: relative;
      span {
        @include transition($transition);
      }
      & .nav__button__overlay {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
      }
      .waiting {
        align-items: center;
        background-color: #c61a0b;
        border-radius: 50%;
        color: #fff;
        font-size: $px14;
        display: inline-flex;
        font-family: 'Google Sans', sans-serif;
        height: 21px;
        justify-content: center;
        opacity: 1;
        position: absolute;
        right: -50%;
        top: 5%;
        width: 21px;
        z-index: 10;
        &.waiting--link {
          @include transition($transition);
          &:hover {
            background-color: #ec2816;
          }
        }
        &.waiting--employee {
          background-color: #2fc60b;
        }
      }
    }
    &:not(.icon) {
      font-weight: 500;
      text-transform: uppercase;
    }

    &.name--desktop {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: $px11;
      text-transform: capitalize;
      white-space: nowrap;
    }

    &.name--mobile {
      display: none;
    }
  }
  &::before,
  &::after {
    bottom: 2px;
    background-color: rgba($color: #fff, $alpha: 0.3);
    border-radius: 30px;
    content: '';
    height: 3px;
    left: 50%;
    position: absolute;
    width: 100%;
    @include transform(translateX(-50%));
    @include transition($transition);
  }

  &::after {
    background-color: #fff;
    width: 0;
  }

  &:hover {
    // background-color: rgba($color: $lightAccent, $alpha: 0.05);
    &::after {
      background-color: #fff;
      width: 100%;
    }
  }

  &.router-link-exact-active,
  &.router-link-active:not(:first-child) {
    color: $lightAccent;
    span {
      &.icon {
        span {
          opacity: 0;
        }
        & .nav__button__overlay,
        & .waiting {
          opacity: 1;
        }
      }
    }
    &::after {
      background-color: $lightAccent;
      width: 100%;
    }
  }
}

.nav__user {
  align-items: center;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  padding-bottom: 5px;
  @include transition($transition);
  @include transform(translateX(70px));
  &:hover {
    @include transform(translateX(0));
  }
  .nav__user-texts {
    margin-right: 7px;
    position: relative;
    text-align: right;
    p {
      font-size: $px16;
      font-weight: 500;
      &:last-child {
        line-height: $px10;
        font-size: $px12;
        font-weight: 400;
      }
    }
    .timer {
      bottom: calc(100% - 5px);
      color: $darkYellow;
      position: absolute;
      right: 0;
      &::before {
        content: '00';
      }
    }
  }

  .nav__user-logout,
  .nav__user-help {
    align-items: center;
    background-color: #35aadf;
    border-radius: 50%;
    color: $mainColor;
    display: flex;
    font-size: $px12;
    height: 30px;
    justify-content: center;
    line-height: $px12;
    margin-left: 5px;
    width: 30px;
    @include hover();
  }

  .nav__user-help {
    background-color: transparent;
    color: #ce6c0b;
    font-size: 30px;
  }
}
.link-help--wrapper {
  display: none;
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .nav-header {
    align-items: center;
    background-color: $mainColor;
    display: flex;
    height: 0;
    justify-content: space-between;
    overflow: hidden;
    left: 0;
    padding: 0 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    @include transition($transition);

    &.nav-header--in {
      height: $header-mobile;
      .nav__logo {
        img {
          opacity: 1;
          @include transform(scale(1));
        }
      }
    }

    .nav__user {
      padding: 0;
      @include transform(initial);

      .nav__user-texts {
        .timer {
          position: initial;
        }
      }
    }
  }

  .nav {
    .nav__button--logout,
    .nav__logo {
      display: none;
    }
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    &.nav--in {
      height: $nav-s;
    }
  }
  .nav__button-group {
    height: initial;
    padding-top: 5px;
    .nav__user {
      display: none;
    }
  }
  .link-help--wrapper {
    display: flex;
    width: calc(100% - 7px);
    justify-content: center;
    position: fixed;
    top: calc(100% - calc($nav-s + 63px));
    z-index: 1;
    .link-help {
      align-items: center;
      background: #ce6c0b;
      border: 2px solid white;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 40px;
      justify-content: center;
      padding: 10px;
      min-height: 65px;
      max-height: 65px;
      min-width: 65px;
      max-width: 65px;
    }
  }
  .nav__button {
    height: initial;
    margin: 0 0.1rem;
    padding-bottom: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &.nav__button--width {
      min-width: 60px;
    }
    > span {
      font-size: 9px;
      &.icon {
        // font-size: $px18;
      }

      // &.name--desktop {
      //   display: none;
      // }

      // &.name--mobile {
      //   display: inline-block;
      // }
    }
    &.nav__button--profile-desktop {
      display: none;
    }
    &.nav__button--profile-mobile {
      display: flex;
    }
  }
  // .timer {
  //   background-color: $mainColor;
  //   bottom: initial;
  //   border-radius: 30px;
  //   padding: 5px 15px;
  //   position: fixed;
  //   height: initial;
  //   right: 25px;
  //   text-decoration: underline;
  //   top: 20px;
  //   &::before {
  //     content: '00';
  //   }
  // }
}
@media screen and (max-width: $xs) {
  .nav {
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav__button-group {
    display: flex;
    &.nav__button-group--for-mobile {
      max-width: 100%;
      justify-content: center;
      width: 100%;
    }
  }
  .nav__button {
    margin: 0 1px;
    // min-width: calc(16.66vw - 2px);
    flex: 1;
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 0;
    // width: calc(100% - 2px);
    &.nav__button--width {
      flex: 1;
      flex-basis: 100%;
    }
    > span:not(.icon) {
      max-width: 100%;
    }
    > span {
      font-size: 8px;
      &.name--desktop {
        line-height: 7px;
        display: block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        // width: 100%;
        > span:first-child {
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
