<template>
  <div class="help">
    <!-- <img src="@/assets/img/help/help.svg" alt="help" /> -->
    <p>
      <a @click.prevent="openFile('employees.mp4')"><img src="@/assets/img/help/1.svg" alt="help" /></a>
    </p>
    <p>
      <a @click.prevent="openFile('supervisors.mp4')"><img src="@/assets/img/help/2.svg" alt="help" /></a>
    </p>
    <p>
      <a @click.prevent="openFile('employees-po.mp4')"><img src="@/assets/img/help/3.svg" alt="help" /></a>
    </p>
    <p>
      <a @click.prevent="openFile('supervisors-po.mp4')"><img src="@/assets/img/help/4.svg" alt="help" /></a>
    </p>
    <p>
      <a @click.prevent="openFile('android.mp4')"><img src="@/assets/img/help/5.svg" alt="help" /></a>
    </p>
    <p>
      <a @click.prevent="openFile('iphone.mp4')"><img src="@/assets/img/help/6.svg" alt="help" /></a>
    </p>
    <p>
      <a @click.prevent="openFile('ed-7.pdf')"><img src="@/assets/img/help/7.svg" alt="help" /></a>
    </p>
    <p>
      <a @click.prevent="openFile('ed-8.pdf')"><img src="@/assets/img/help/8.svg" alt="help" /></a>
    </p>
    <span v-if="!currentUser._id">Ако сте си забравили паролата, се обърнете към вашия ръководител, или към УЧР</span>
    <span
      >Ако имате затруднение с пускането на отпуск, или с качването на документ, моля да звъннете на вътрешни 9580 и
      9490</span
    >
    <a v-if="!currentUser._id" href="/" class="go-back">ВЪРНЕТЕ СЕ НАЗАД</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    openFile(f) {
      const url = `${process.env.VUE_APP_STATIC_URI}/${f}`;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.help {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  span {
    max-width: 260px;
    font-weight: bold;
    margin-top: 5px;
  }
  a {
    display: inline-block;
    @include hover();
    &.go-back {
      font-weight: bold;
      color: $darkBlue;
      text-decoration: underline;
      margin-top: 20px;
    }
  }
  a:hover {
    transform: scale(1.05);
  }
}
@media screen and (max-width: $sm) {
  .help {
    justify-content: initial;
    span {
      &:last-child {
        padding-bottom: 20px;
      }
    }
  }
}
</style>