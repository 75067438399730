<template>
  <div
    class="input-wrapper"
    :class="{
      'input-wrapper--invalid': invalid,
      'input-wrapper--datepicker': datepicker,
      'input-wrapper-less--css': lessCss,
      'input-wrapper--white': $route.name === 'newVacation' || $route.name === 'editVacation',
    }"
    v-click-outside="clickedOutside"
  >
    <label v-if="label">
      {{ label }}
    </label>
    <!-- DROPDOWNS -->
    <template v-if="list">
      <div class="dropdown-toggle">
        <template>
          <input
            type="text"
            :placeholder="open ? 'Изберете...' : getValue ? getValue : placeholder"
            :disabled="disabled"
            v-model="search"
            @focus="(open = true), $emit('focus')"
            ref="dd"
          />
          <span class="icon" :class="{ icon__reversed: up }" @click="$refs.dd.focus()"> E </span>
        </template>
      </div>
      <div class="input-wrapper__dropdown" :class="{ 'input-wrapper__dropdown--up': up }" v-if="open">
        <p
          v-for="(l, i) in filteredList"
          :key="`dd-l-${i}`"
          :class="{ selected: isSelected(l) }"
          @mousedown="listItemSelected(l)"
        >
          {{ prop ? l[prop] : l }}
        </p>
        <p v-if="!filteredList.length" class="empty">няма резултати</p>
      </div>
    </template>
    <!-- DATEPICKERS -->
    <div
      class="fake-input"
      :class="{
        'fake-input--active': isDatepickerModalOpen,
        'fake-input--disabled': disabled,
      }"
      @click="disabled ? null : (isDatepickerModalOpen = true)"
      v-else-if="datepicker"
    >
      <template v-if="month">
        {{ value | formatDateMonth }}
      </template>
      <template v-else>
        {{ value | formatDate }}
      </template>
    </div>
    <!-- TIMEPICKER -->
    <div
      class="fake-input"
      :class="{
        'fake-input--active': isTimepickerModalOpen,
        'fake-input--disabled': disabled,
      }"
      @click="disabled ? null : (isTimepickerModalOpen = true)"
      v-else-if="timepicker"
    >
      {{ value || 'ЧЧ:ММ' }}
    </div>
    <!-- FILES -->
    <label
      class="fake-input fake-input--file"
      :class="{ 'fake-input--active': false, 'fake-input--disabled': disabled }"
      v-else-if="type === 'file'"
      :for="`file-${_uid}`"
    >
      <span v-if="!files">Прикачи снимка - jpeg, png или gif of 2 МБ</span>
      <span v-else>
        <template v-if="!files.length">1 избран файл</template>
        <template v-else>{{ files.length }} избрани файла</template>
      </span>
      <span class="icon">G</span>
      <input
        type="file"
        :id="`file-${_uid}`"
        :accept="accept ? accept : true"
        :multiple="multiple ? multiple : false"
        :disabled="disabled"
        @change="filesSelected"
      />
    </label>
    <!-- STANDART INPUT -->
    <input
      :type="type || 'text'"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :value="value"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      v-else
    />
    <!-- MODALS -->
    <transition name="fade">
      <DatepickerModal
        v-if="isDatepickerModalOpen"
        :value="value"
        :disabledDates="disabledDates"
        :month="month"
        @selected="dateSelected"
        @close="isDatepickerModalOpen = false"
      />
    </transition>
    <transition name="fade">
      <TimepickerModal
        v-if="isTimepickerModalOpen"
        :value="value"
        :min="min"
        :max="max"
        @input="$emit('input', $event)"
        @close="isTimepickerModalOpen = false"
      />
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import DatepickerModal from './DatepickerModal';
import TimepickerModal from './TimepickerModal';
export default {
  props: [
    'value',
    'placeholder',
    'list', // should be array of items
    'prop', // prop is the name you wish to display if list contains objects
    'label',
    'type',
    'disabled',
    'disabledDates', // datepicker only
    'month', // datepicker only
    'datepicker',
    'timepicker',
    'min', // used for timepicker only
    'max', // used for timepicker only
    'invalid',
    'files',
    'up', // open over the input
    'lessCss',
    'multiple',
    'accept',
    'identifier',
    'autocomplete',
  ],
  directives: {
    ClickOutside,
  },
  components: {
    DatepickerModal,
    TimepickerModal,
  },
  computed: {
    getValue() {
      let value = this.value;
      if (value) {
        if (this.type === 'dropdown') {
          if (this.multiple) {
            value = this.prop ? this.value.map((x) => x[this.prop]).join(', ') : this.value.join(', ');
          } else value = this.prop ? this.value[this.prop] : this.value;
        }
      }

      return value;
    },
    filteredList() {
      if (this.prop) {
        return this.list;
      } else {
        return this.list.filter((x) => {
          return (x + '').toLowerCase().includes(this.search.toLowerCase());
        });
      }
    },
  },
  watch: {
    search() {
      this.$emit('search', this.search);
    },
  },
  data() {
    return {
      open: false,
      isDatepickerModalOpen: false,
      isTimepickerModalOpen: false,
      search: '',
    };
  },
  methods: {
    clickedOutside() {
      if (this.list) {
        if (this.open) {
          this.open = false;
          this.search = '';
        }
      }
    },
    listItemSelected(l) {
      if (this.multiple) {
        if (this.identifier) {
          const i = this.value.map((x) => x[this.identifier]).indexOf(l[this.identifier]);
          if (i > -1) {
            const value = [...this.value];
            value.splice(i, 1);
            this.$emit('input', value);
          } else {
            this.$emit('input', [...this.value, l]);
          }
        }
      } else {
        this.$refs.dd.blur();
        this.open = false;
        this.$emit('input', l);
        this.$emit('item-selected');
        this.search = '';
      }
    },
    isSelected(l) {
      if (this.identifier) {
        if (this.multiple) return this.value.map((x) => x[this.identifier]).indexOf(l[this.identifier]) > -1;
        else return this.value[this.identifier] === l[this.identifier];
      }
      return;
    },
    dateSelected(e) {
      this.$emit('input', e);
      this.$emit('date-selected');
      this.isDatepickerModalOpen = false;
    },
    filesSelected(e) {
      if (e.target.files.length <= 5) {
        this.$emit('files-selected', e.target.files[0]);
      } else {
        this.$snotify.error('Не може да избирате повече от 5 файла');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.input-wrapper {
  position: relative;
  width: 205px;
  @include noselect();
  &.input-wrapper--big {
    width: 400px;
  }
  &.input-wrapper--small {
    width: 100px;
  }
  &.input-wrapper--100 {
    width: 100px;
    .fake-input {
      white-space: nowrap;
    }
  }
  &.input-wrapper-less--css {
    .fake-input,
    .dropdown-toggle,
    input {
      background: transparent;
      border: none;
      color: white;
      font-weight: 600;
      font-size: 14px;
      height: fit-content;
      padding: 0;
      &.fake-input--active,
      &:focus {
        background-color: transparent;
        border: none;
      }
      &:hover,
      &:disabled {
        background-color: transparent;
      }
    }
  }
  label:not(.fake-input) {
    color: #373737;
    display: inline-flex;
    font-size: $px11;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .fake-input,
  .dropdown-toggle,
  input {
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    color: #373737;
    display: inline-flex;
    font-size: $px12;
    height: 36px;
    outline: none;
    padding: 0 $px12;
    width: 100%;
    @include transition($transition);
    &:disabled {
      background-color: #fff;
    }
    &::placeholder {
      color: inherit;
      font-style: italic;
    }
    &.fake-input--active,
    &:focus {
      background-color: #fff;
      box-shadow: 0px 0px 0px 1px #378ef0;
      border: 1px solid #378ef0;
    }
  }
  .dropdown-toggle {
    align-items: center;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    padding: 0;
    input {
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(100% - 30px);
    }
    &:hover {
      background-color: rgba($color: #f6f6f6, $alpha: 0.8);
    }
  }

  .fake-input {
    &:not(.fake-input--disabled) {
      cursor: pointer;
    }
    &.fake-input--file {
      position: relative;
      .icon {
        color: #eac282;
        font-size: 1.5rem;
        right: 0.5rem;
      }
    }
  }

  .icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    @include transform(translateY(-50%));
    &.icon__reversed {
      @include transform(translateY(-60%) scaleY(-1));
    }
  }

  .input-wrapper__dropdown {
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0px 1px 4px #00000026;
    border: 1px solid #d3d3d3;
    left: 0;
    position: absolute;
    max-height: 30vh;
    overflow: auto;
    top: calc(100% + 6px);
    width: 100%;
    z-index: 10;
    @include scrollbar(5px, #004d94);

    > p {
      cursor: pointer;
      padding: 0.6rem 0.8rem;
      width: 100%;
      &.selected {
        background: rgb(0, 77, 148, 0.2);
      }
      &:not(.empty):not(.selected) {
        &:hover {
          background-color: #f6f6f6;
        }
      }
    }
    &.input-wrapper__dropdown--up {
      bottom: 42px;
      top: initial;
    }
  }
  &.input-wrapper--invalid {
    .dropdown-toggle,
    .dropdown-toggle--active,
    input,
    input:focus {
      box-shadow: 0px 0px 0px 1px $darkRed;
      border: 1px solid $darkRed;
    }
  }

  input[type='file'] {
    height: 0.01px;
    left: -100vw;
    opacity: 0;
    position: absolute;
    width: 0.01px;
    z-index: -1;
  }

  &.input-wrapper--white {
    .fake-input,
    .dropdown-toggle,
    input {
      background-color: #fff;
    }
  }
}
@media print {
  .input-wrapper {
    align-items: flex-end;
    display: flex;

    .icon {
      display: none;
    }
    label:not(.fake-input) {
      margin: 0 0.5rem 0 0;
      white-space: nowrap;
    }
    .fake-input,
    .dropdown-toggle,
    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px dashed #505050;
      height: 30px;
      &:disabled {
        background-color: transparent;
      }
      &::placeholder {
        color: transparent;
      }
    }
    &.input-wrapper--datepicker {
      .fake-input {
        width: 100px;
      }
    }
  }
}
</style>
