<template>
  <div class="sidepanel__login">
    <div>
      <transition name="appear" appear>
        <img src="@/assets/img/logo_auth.svg" alt="KCM 2000 ePortal" class="sidepanel__login__logo" />
      </transition>
    </div>
    <main>
      <form>
        <input type="text" placeholder="Служебен номер" v-model="user.number" />
        <input type="password" placeholder="Парола" v-model="user.password" />
        <button @click.prevent="login">ВХОД</button>
        <p class="error">
          <transition name="fade">
            <span v-if="error">{{ error }}</span>
          </transition>
        </p>
      </form>
      <footer>
        <router-link to="/help" class="help">НУЖДА ОТ ПОМОЩ?</router-link>
        <a href="https://cdots.bg/" class="cdots" target="_blank"
          >{{ date.getFullYear() }} Решението е разработено от cDots</a
        >
      </footer>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../../.././config-public.json';
export default {
  computed: {
    ...mapGetters(['activeCompany']),
  },
  data() {
    return {
      config,
      date: new Date(),
      user: {
        number: '',
        password: '',
      },
      error: '',
    };
  },
  methods: {
    login() {
      if (this.user.number && this.user.password) {
        this.error = '';
        this.$store
          .dispatch('login', this.user)
          .then(() => {
            if (this.$route.query.exFrom) {
              window.location.href = this.$route.query.exFrom;
            }
          })
          .catch((err) => {
            // console.log(err);
            this.error = err.response.data.message;
          });
      } else {
        this.error = 'Моля въведете служебен номер и парола';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__login {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 22px;
  position: relative;
  width: 100%;
}

.sidepanel__login__logo {
  width: 100%;
}
main {
  width: 100%;
  form {
    input,
    button {
      border-radius: 24px;
      border: 0;
      height: 48px;
      outline: none;
      width: 100%;
    }

    input {
      display: block;
      font-size: $px14;
      font-weight: 500;
      margin-bottom: 20px;
      padding: 0 20px;
      &::placeholder {
        color: rgba($color: $mainColor, $alpha: 0.5);
      }
    }

    button {
      align-items: center;
      background-color: $darkBlue;
      color: #fff;
      cursor: pointer;
      font-size: $px12;
      font-weight: 700;
      display: flex;
      justify-content: center;
      @include transition($transition);
      &:hover {
        background-color: rgba($color: $darkBlue, $alpha: 0.8);
      }
    }
  }
}

.error {
  line-height: 1rem;
  min-height: 1rem;
  margin: 1rem 0 0.5rem;
  text-align: center;
}
footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $px10;

  .help {
    color: #fff;
    text-decoration: underline;
    margin-bottom: 5px;
  }
  .cdots {
    color: #fff;
    text-transform: uppercase;
    opacity: 0.5;
  }
}
</style>