<template>
  <main class="user-reports">
    <h1 class="main-title">СПРАВКА HOME OFFICE ЗА {{ user.name }}, СЛУЖ. №{{ user.number }}</h1>

    <section class="filters">
      <div class="filters-wrapper">
        <section>
          <Input
            datepicker="true"
            v-model="dateFrom"
            @input="resetReports()"
            :disabledDates="disabledDatesFrom"
            lessCss="true"
          />
          <span class="label">Справка ОТ</span>
        </section>
        <section>
          <Input
            datepicker="true"
            v-model="dateTo"
            @input="resetReports()"
            :disabledDates="disabledDatesTo"
            lessCss="true"
          />
          <span class="label">Справка ДО</span>
        </section>
        <section>
          <Input
            type="dropdown"
            :list="categories"
            :multiple="true"
            placeholder="Категория"
            prop="name"
            identifier="name"
            lessCss="true"
            v-model="category"
            @input="reportsLoaded = false"
            @search="gethOCategories($event)"
          />
          <span class="label">Категория</span>
        </section>
      </div>

      <div>
        <Button v-if="!reportsLoaded" class="button--blue" @click="getReports()">Направи справка</Button>
        <Button v-else class="button--red" @click="resetReports()">Нулирай справка</Button>
      </div>
    </section>

    <main class="reference-content" v-if="reportsLoaded">
      <!-- FIRST TABLE -->
      <section>
        <h1>
          справка home office за период <b>{{ dateFrom | formatDate }} - {{ dateTo | formatDate }}</b>
        </h1>
        <Table>
          <tbody>
            <tr>
              <td style="width: 95%">Работни дни за избрания период</td>
              <td>{{ workDays(true) }}</td>
            </tr>
            <tr>
              <td style="width: 95%">Дни в Хоум офис</td>
              <td>{{ homeOfficeDays().length }}</td>
            </tr>
            <tr>
              <td style="width: 95%">Неотчетени дни в Хоум офис (от общия бр. дни Хоум офис)</td>
              <td>{{ notReportedDays() }}</td>
            </tr>
            <tr>
              <td style="width: 95%">Незавършени дни в Хоум офис (от общия бр. дни Хоум офис)</td>
              <td>{{ notFinishedReports() }}</td>
            </tr>
            <tr>
              <td style="width: 95%">Среден брой задачи на ден</td>
              <td>{{ tasksCount() }}</td>
            </tr>
            <tr>
              <td style="width: 95%">Средно време на задача</td>
              <td>{{ tasksCount(true) }}</td>
            </tr>
          </tbody>
        </Table>
      </section>
      <!-- SECOND TABLE -->
      <section>
        <h1>
          справка по категории - брой задачи, за период <b>{{ dateFrom | formatDate }} - {{ dateTo | formatDate }}</b>
        </h1>
        <Table>
          <tbody>
            <tr v-for="(c, i) of categories" :key="`${c}-${i}`" v-show="category.length ? category.includes(c) : true">
              <td style="width: 80%">{{ c.name }}</td>
              <td class="td--lower-opacity">бр. задачи</td>
              <td>{{ getCategoryCount(c.name) }}</td>
            </tr>
          </tbody>
        </Table>
      </section>
      <!-- THIRD TABLE -->
      <section>
        <h1>
          списък на отчетените задачи за период <b>{{ dateFrom | formatDate }} - {{ dateTo | formatDate }}</b>
          <span @click="reports.length ? exportToExcelFile() : null"> СВАЛЯНЕ НА СПИСЪКА - XLSX ФАЙЛ</span>
        </h1>
        <Table v-if="homeOfficeDays().length" id="reference-table">
          <thead>
            <tr>
              <th>СПРАВКА ХОУМ ОФИС ЗА ПЕРИОД {{ dateFrom | formatDate }} - {{ dateTo | formatDate }}</th>
            </tr>
            <tr>
              <th>ЗА {{ user.name }}, СЛ. НОМЕР {{ user.number }}, ДЛЪЖНОСТ {{ user.position.name }}</th>
            </tr>
            <tr></tr>
            <tr>
              <th>Дата</th>
              <th>Задачи за деня</th>
              <th>Категория</th>
              <th>Описание на задачата</th>
              <th>Време</th>
            </tr>
          </thead>
          <tbody
            v-for="day of homeOfficeDays()"
            :key="day.fullDate"
            class="tbody--borders"
            :class="{ draft: day.report ? day.report.draft : false, expired: !day.report && day.expiredReport }"
            v-show="today >= new Date(day.fullDate)"
          >
            <template v-if="day.report">
              <tr
                v-for="(t, i) of day.report.tasks"
                :key="`${t}-${i}`"
                v-show="category.length ? category.filter((x) => x.name === t.category.name)[0] : true"
              >
                <td v-if="i === 0">{{ day.report.createdOn | formatDateExcel }}</td>
                <td v-else></td>
                <td class="td--no-wrap">Задача {{ i + 1 }}</td>
                <td class="td--no-wrap">{{ t.category.name }}</td>
                <td style="width: 60%">{{ t.description }}</td>
                <td class="td--no-wrap">{{ day.report.draft ? 'Незавършен отчет' : t.time }}</td>
              </tr>
            </template>
            <template v-else>
              <tr v-show="!category.length">
                <td>{{ day.fullDate | formatDateExcel }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td class="td--no-wrap">{{ day.expiredReport ? 'Неотчетен ден' : 'Очакващ отчитане' }}</td>
              </tr>
            </template>
          </tbody>
        </Table>
        <main v-else class="no-results">Няма резултати</main>
      </section>
    </main>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from '@/components/Table';
import Input from '@/components/inputs/Input';
import Button from '@/components/Button';
// import categories from '@/data/report-categories.json';
import XLSX from 'xlsx-js-style';
export default {
  components: {
    Button,
    Input,
    Table,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
    disabledDatesFrom() {
      const disabled = {
        ...this.disabledDates,
      };
      if (this.dateTo) disabled.from = this.dateTo;
      return disabled;
    },
    disabledDatesTo() {
      const disabled = {
        ...this.disabledDates,
      };
      if (this.dateFrom) disabled.to = this.dateFrom;
      return disabled;
    },
  },
  data() {
    return {
      reportsLoaded: false,
      reports: [],
      vacationsHourly: [],
      dateFrom: '',
      dateTo: '',
      user: [],
      categories: [],
      category: [],
      dates: [],
      shifts: [],
      today: new Date(new Date().setHours(12, 0, 0, 0)),
    };
  },
  methods: {
    gethOCategories(search) {
      this.categories = [];
      let url = '/users/category/home-office-categories';

      if (search) {
        url += `?search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.categories = res.data;
      });
    },
    notReportedDays() {
      let notFinishedReports = this.homeOfficeDays().filter((day) =>
        day.expiredReport && !day.report && day.shift ? true : false
      );

      return notFinishedReports.length;
    },
    notFinishedReports() {
      let notFinishedReports = this.homeOfficeDays().filter((day) => (day.report ? day.report.draft : false));

      return notFinishedReports.length;
    },
    workDays(returnSize) {
      let workdays = [];

      if (this.activeCompany === 'КЦМ АД') {
        workdays = this.dates.filter((day) => day.shift);
      } else {
        workdays = this.dates.filter((day) => day.holiday === null && day.weekend === false);
      }
      return returnSize ? workdays.length : workdays;
    },
    homeOfficeDays() {
      let homeOfficeDays = [];

      for (let v of this.vacationsHourly) {
        if (v.type === '0016') {
          for (let i = new Date(v.dateFrom); i <= new Date(v.dateTo); i.setDate(i.getDate() + 1)) {
            let filterHomeOffice = this.workDays().filter((el) => new Date(el.fullDate).toString() === i.toString());
            if (filterHomeOffice.length) homeOfficeDays.push(filterHomeOffice[0]);
          }
        }
      }
      if (homeOfficeDays.length) {
        homeOfficeDays.forEach((element) => {
          for (let report of this.reports) {
            let reportDay = new Date(report.createdOn);
            let day = new Date(element.fullDate);

            let reportDaySetHours = new Date(day.setHours(0, 0, 0, 0));
            let daySetHours = new Date(reportDay.setHours(0, 0, 0, 0));

            if (reportDaySetHours.toString() === daySetHours.toString()) {
              this.$set(element, 'report', report);
            }
          }
        });
      }

      return homeOfficeDays;
    },
    tasksCount(avgTaskTime) {
      if (this.reports.length) {
        let tasksCount = 0;
        let tasksHoursCount = 0;
        let result = 0;
        let homeOfficeDays = this.homeOfficeDays().length;

        for (let report of this.reports) {
          tasksCount += report.tasks.length;
          for (let task of report.tasks) {
            tasksHoursCount += task.time ? task.time : 0;
          }
        }
        let num2 = avgTaskTime ? tasksHoursCount : homeOfficeDays;
        result = num2 > tasksCount ? num2 / tasksCount : tasksCount / num2;

        return homeOfficeDays <= 0 ? 0 : result.toFixed(2);
      } else return 0;
    },
    exportToExcelFile() {
      const wscols = [{ wpx: 100 }, { wpx: 150 }, { wpx: 150 }, { wpx: 400 }, { wpx: 200 }];
      const config = { dateNF: 'dd.mm.yyyy' };

      // Convert Table to Worksheet
      const referencesSheet = XLSX.utils.table_to_sheet(document.getElementById('reference-table'), config);
      // Define New Workbook
      const new_workbook = XLSX.utils.book_new();

      referencesSheet['!cols'] = wscols;

      const style = { font: { bold: true, sz: 12 } };
      referencesSheet['A1'].s = style;
      referencesSheet['A2'].s = style;
      referencesSheet['A4'].s = style;
      referencesSheet['B4'].s = style;
      referencesSheet['C4'].s = style;
      referencesSheet['D4'].s = style;
      referencesSheet['E4'].s = style;

      // Append Worksheet
      XLSX.utils.book_append_sheet(new_workbook, referencesSheet, `Отчети`);
      // Save File

      XLSX.writeFile(
        new_workbook,
        `Отчети - ${this.$options.filters.formatDate(this.dateFrom)} - ${this.$options.filters.formatDate(
          this.dateTo
        )} - ${this.user.name} - .xlsx`
      );
    },
    generatePattern(d) {
      const firstDayOfScheduleGroup = new Date(d.scheduleGroup.dateFrom);
      const date = new Date(d.fullDate);
      const difference = Math.ceil(Math.abs(date - firstDayOfScheduleGroup) / (1000 * 60 * 60 * 24));
      let offset = 1;
      if (firstDayOfScheduleGroup < date) {
        offset = difference % d.scheduleGroup.pattern.length;
      }

      const sc = d.scheduleGroup.schedules;
      let position = offset ? offset - 1 : sc.pattern.length - 1;

      if (d.scheduleGroup.skipHolidays && d.holiday) {
        this.$set(d, 'shift', null);
      } else {
        this.$set(d, 'shift', d.scheduleGroup.shifts[sc.pattern[position] - 1]);
        if (d.shift) {
          this.$set(d.shift, 'i', sc.pattern[position]);
        }
      }
    },
    getDates() {
      // GETTING THE DATES NEEDS TO HAPEN IN THE BACKEND

      let url = `/users/days?type=period&dateFrom=${this.dateFrom}&dateTo=${this.dateTo}`;

      this.$apiService.get(url).then((res) => {
        this.dates = res.data;

        if (this.dates.length) {
          for (let d of this.dates) {
            let url = `/users/schedule-groups/${this.user.schedule}/${d.fullDate}`;

            this.$apiService
              .get(url)
              .then((res) => {
                this.$set(d, 'scheduleGroup', res.data);
                this.generatePattern(d);

                let url = `/users/schedule-groups/${this.user.schedule}/${d.fullDate}/users?user=${this.user._id}&ignoreRoles=1`;
                url += `&weekend=${d.weekend}`;
                url += `&shift=${d.shift ? true : false}`;

                this.$apiService
                  .get(url)
                  .then((res) => {
                    if (res.data) {
                      const x = res.data[0];
                      if (x) {
                        if (!d.shift && (x.vacations || x.absence)) this.$set(d, 'shift', {});

                        if (x.vacations) {
                          this.$set(d.shift, 'hours', '');
                          this.$set(d.shift, 'name', 'О');
                          this.$set(d.shift, 'i', 'vacation');
                        } else if (x.absence) {
                          this.$set(d.shift, 'hours', '');
                          if (x.absence === 'shift-change') {
                            this.$set(d.shift, 'i', 'orange');
                            this.$set(d.shift, 'name', x.shift);
                          } else {
                            const types = {
                              illness: 'Б',
                              maternity: 'М',
                              'self-release': 'С',
                            };
                            this.$set(d.shift, 'i', x.absence);
                            this.$set(d.shift, 'name', types[x.absence]);
                          }
                        }
                      }
                    }
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          }
        }
      });
    },
    getVacationsHourly() {
      let url = `/users/${this.$route.params.id}/vacations-hourly?type=0016`;

      this.$apiService
        .get(url)
        .then((res) => {
          if (res.data) {
            this.vacationsHourly = res.data.vacationsHourly;
          }
        })
        .catch((err) => console.log(err.response.data));
    },
    getCategoryCount(c) {
      let count = 0;
      for (let report of this.reports) {
        for (let task of report.tasks) {
          if (task.category.name === c) count++;
        }
      }
      return count;
    },
    resetReports() {
      this.reports = [];
      this.reportsLoaded ? (this.category = []) : '';
      this.reportsLoaded = false;
    },
    getReports() {
      this.reports = [];
      this.reportsLoaded = false;
      let url = `/users/${this.$route.params.id}/reports?dateFrom=${this.dateFrom}&dateTo=${this.dateTo}`;

      this.$apiService.get(url).then((res) => {
        this.reports = res.data.reports;
          this.getDates();
          this.getVacationsHourly();
          this.reportsLoaded = true;
      });
    },
    getUser() {
      this.user = [];
      let url = `/users/${this.$route.params.id}`;

      this.$apiService.get(url).then((res) => {
        this.user = res.data;
      });
    },
  },
  mounted() {
    this.getUser();
    this.gethOCategories();

    this.dateFrom = new Date(new Date().getFullYear(), 8, 1);
    this.dateTo = new Date(new Date().getFullYear(), 8, 31);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.user-reports {
  padding: 1rem;
  max-width: 1000px;
  margin: 0 auto;

  .no-results {
    text-align: center;
  }
  h1 {
    color: #373737;
    text-transform: uppercase;
    text-align: center;
    text-decoration: underline;
    font-size: $px14;
    font-weight: normal;
    margin: 20px 0;
    &.main-title {
      font-weight: bold;
      text-decoration: none;
    }
    span {
      cursor: pointer;
      color: #5476ff;
      text-decoration-color: #5476ff;
      margin-left: 10px;
    }
  }

  .filters {
    align-items: center;
    background: #102538 0% 0% no-repeat padding-box;
    border-radius: 10px;
    display: flex;
    padding: 1.2rem 1rem;
    margin: 1rem 0;
    > .filters-wrapper {
      align-items: center;
      display: flex;
      gap: 15px;
      width: 100%;
      .label {
        color: white;
        display: block;
        margin-top: 2px;
        white-space: nowrap;
        opacity: 0.5;
      }

      section {
        .input-wrapper {
          width: fit-content;
        }
        &:last-child {
          width: 100%;
          .input-wrapper {
            width: calc(100% - 15px);
          }
        }

        &:nth-child(2) {
          border-right: 1px solid rgb(255, 255, 255, 0.5);
          border-left: 1px solid rgb(255, 255, 255, 0.5);
          padding: 0 10px;
        }
      }
    }
  }
  thead {
    display: none;
  }
  tbody {
    td,
    tr {
      cursor: default;
      &.td--lower-opacity {
        color: rgb(55, 55, 55, 0.5);
      }
      &.td--no-wrap {
        white-space: nowrap;
      }
    }
    tr {
      &:first-child {
        border-top: 1px solid #d3d3d3;
      }
    }
    &.tbody--borders {
      border-top: 2px solid #373737;
      border-bottom: 2px solid #373737;
    }
    &.draft {
      color: #e17800;
    }
    &.expired {
      color: #de2a2a;
    }
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .user-reports {
    padding: 0.5rem;
    .filters {
      flex-wrap: wrap;
      justify-content: center;
      padding: 1.2rem 0.5rem;
      div,
      section {
        width: 100%;
      }
      button {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}
</style>
