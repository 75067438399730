<template>
  <div class="home" :class="{ 'home--in': isAuthenticated, 'home--2000': activeCompany === 'КЦМ 2000' }">
    <transition name="fade">
      <img v-if="isAuthenticated" src="../assets/img/home/bg_in.jpg" alt="Background image" key="image-auth" />
      <img v-else src="../assets/img/home/bg.png" alt="Background image" key="image-login" />
    </transition>
    <!-- <transition name="fade" appear>
      <nav :class="`active--${tab}`" v-if="isAuthenticated">
        <a :class="{ active: tab === 'portal' }" @click="tab = 'portal'">ПОРТАЛ</a>
        <a :class="{ active: tab === 'news' }" @click="tab = 'news'">НОВИНИ</a>
        <a :class="{ active: tab === 'events' }" @click="tab = 'events'">СЪБИТИЯ</a>
      </nav>
    </transition> -->
    <transition name="fade" appear>
      <header class="desktop" v-if="isAuthenticated">
        <div>
          <h2 class="home__title">ДОБРЕ ДОШЛИ В ПОРТАЛА НА ХОЛДИНГ КЦМ 2000</h2>
        </div>
        <div>
          <p>Изберете фирма от групата на КЦМ, за да<br />виждате конкретна информация само за нея</p>
          <button @click="$store.dispatch('selectCompany', null)">
            {{ activeCompany || 'Избиране...' }}
            <span class="icon" v-if="activeCompany">E</span>
          </button>
        </div>
      </header>
    </transition>
    <div class="home__content">
      <!-- <div class="home__content desktop"> -->
      <transition name="fade" appear>
        <HomeCards v-if="isAuthenticated" />
      </transition>
      <!-- <transition name="fade-left" appear>
        <main v-if="isAuthenticated">
          <HomeCards />
          <HomeNews />
        </main>
      </transition>
      <transition name="fade-right" appear>
        <aside v-if="isAuthenticated">
          <HomeEvents />
        </aside>
      </transition> -->
    </div>
    <!-- <div class="home__content mobile">
      <transition name="fade-left" appear>
        <main v-if="isAuthenticated">
          <transition name="fade" mode="out-in" appear>
            <HomeCards v-if="tab === 'portal'" />
            <HomeNews v-else-if="tab === 'news'" />
            <HomeEvents v-else-if="tab === 'events'" />
          </transition>
        </main>
      </transition>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HomeCards from './HomeCards';
// import HomeNews from './HomeNews';
// import HomeEvents from './HomeEvents';
export default {
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser', 'activeCompany']),
  },
  components: {
    HomeCards,
    // HomeNews,
    // HomeEvents,
  },
  name: 'Home',
  data() {
    return {
      tab: 'portal',
    };
  },
  mounted() {
    if (this.$route.query['2fa']) {
      this.$router
        .replace(this.$route.query.openDocument ? `/?openDocument=${this.$route.query.openDocument}` : '/')
        .then(() => {
          this.$store.dispatch('setTwoFAMode', { state: true });
        });
    }
  },
};
</script>

<style lang="scss" >
@import '@/scss/base.scss';
.home {
  align-items: center;
  background-color: #f6fcff;
  background-size: cover;
  display: flex;
  font-family: 'SFRounded';
  flex-direction: column;
  justify-items: center;
  min-height: 100%;
  position: relative;

  > img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .home__title {
    color: #004d94;
    font-size: $px20;
    &.mobile {
      display: none;
    }
  }

  > header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 3rem;
    position: relative;
    width: 100%;

    > div {
      align-items: center;
      display: flex;
      p {
        text-align: right;
        margin-right: 1rem;
      }
      button {
        align-items: center;
        background-color: #fff;
        border: 1px solid #35aadf;
        border-radius: 4px;
        color: #000;
        display: flex;
        height: 32px;
        justify-content: space-between;
        padding: 0 $px12;
        width: 221px;
        @include hover();
      }
    }
  }
  nav {
    display: none;
  }
}

.home__content {
  display: flex;
  justify-items: center;
  min-height: 100%;
  gap: 4rem;
  padding: 2rem 3rem;
  position: relative;
  width: 100%;

  // remove if news and calendar are added again
  flex-grow: 1;
  align-items: center;
  // ^ remove if news and calendar are added again
  &.mobile {
    display: none;
  }
  > aside {
    flex-basis: 20%;
    min-width: 320px;
  }
}

.home__logo {
  img {
    width: 204px;
  }
}

@media screen and (max-width: $sm) {
  .home__content {
    gap: 2rem;
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .home {
    padding-top: 1rem;
    .home__title {
      text-align: center;
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
        font-size: $px16;
        margin: 0 15%;
      }
    }
    nav {
      background-color: #bdccda;
      border-radius: 10px;
      display: flex;
      position: relative;
      width: calc(100% - 2rem);

      &::before {
        background-color: #004c92;
        border-radius: inherit;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 33.33%;
        @include transition($transition);
      }

      &.active--news::before {
        left: 33.33%;
      }
      &.active--events::before {
        left: 66.66%;
      }

      a {
        align-items: center;
        border-radius: inherit;
        cursor: pointer;
        display: flex;
        flex-basis: 33.33%;
        font-size: $px20;
        font-weight: 500;
        height: 35px;
        justify-content: center;
        position: relative;
        width: 33.33%;
        @include transition($transition);
        &:nth-child(2) {
          &::before,
          &::after {
            background-color: #8ea8c1;
            content: '';
            height: 60%;
            position: absolute;
            top: 20%;
            width: 1px;
          }
          &::before {
            left: 0;
          }
          &::after {
            right: 0;
          }
        }
        &.active {
          color: #fff;
          font-weight: 700;
        }
      }
    }
    > header {
      flex-direction: column;
      text-align: center;
      padding: 1rem;

      &.desktop {
        display: none;
      }

      > div {
        flex-direction: column;
        p {
          margin: 1rem 0;
        }
      }
    }
  }
  .home__content {
    flex-direction: column;
    gap: 0;
    padding: 10px;
    width: 100%;
    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
    }
  }
}
</style>
