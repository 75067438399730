<template>
  <div class="sidepanel__user">
    <Button
      class="button--big button--grey"
      @click="backToAllUsers"
      v-if="currentUser.roles.admin || currentUser.roles.hr || currentUser.roles.supervisor"
    >
      <span class="icon">B</span>
      <template v-if="$route.query.backLabel">{{ $route.query.backLabel }}</template>
      <template v-else> НАЗАД КЪМ ВСИЧКИ СЛУЖИТЕЛИ </template>
    </Button>
    <template v-if="$route.name !== 'newUser'">
      <div class="sidepanel__texts">
        <p class="title">Профил</p>
        <p>Преглед и настройки</p>
      </div>
      <router-link
        class="sidepanel__link"
        :to="`/users/${$route.params.id}`"
        v-if="currentUser.roles.admin || currentUser.roles.hr || currentUser._id === $route.params.id"
        >ЛИЧНИ ДАННИ</router-link
      >
      <router-link
        class="sidepanel__link"
        :to="`/users/${$route.params.id}/permissions`"
        v-if="currentUser.roles.admin"
      >
        РОЛИ И ДОСТЪП
      </router-link>
      <router-link class="sidepanel__link" :to="`/users/${$route.params.id}/vacations`"> ОТПУСК </router-link>
      <router-link
        class="sidepanel__link"
        :to="`/users/${$route.params.id}/references`"
        v-if="currentUser.roles.admin || currentUser.roles.supervisor || currentUser.roles.hr"
      >
        ОТЧЕТ ХОУМ ОФИС
      </router-link>
      <router-link class="sidepanel__link" :to="`/users/${$route.params.id}/vacations-hourly`"
        >ПОЧАСОВИ ОТСЪСТВИЯ</router-link
      >
      <a
        :class="{ 'router-link-exact-active': $route.name === 'userTrdoc' }"
        @click="open2Fa()"
        class="sidepanel__link"
        v-if="activeCompany === 'КЦМ АД'"
        >ЕЛЕКТРОННО ТРУДОВО ДОСИЕ</a
      >
      <!-- `/users/${$route.params.id}/trdoc` -->
      <!-- <router-link
        class="sidepanel__link"
        :to="`/users/${$route.params.id}/lps`"
      >
        ЛИЧНИ ПРЕДПАЗНИ СРЕДСТВА
      </router-link>
      <router-link
        class="sidepanel__link"
        :to="`/users/${$route.params.id}/activities`"
      >
        АКТИВНОСТ
      </router-link> -->
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '../Button';
export default {
  components: {
    Button,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },
  methods: {
    async open2Fa() {
      let has2Fa = await this.$store.dispatch('setTwoFAMode', { state: true, stayInMyKcm: true });
      if (has2Fa) this.$router.push(`/users/${this.$route.params.id}/trdoc`);
    },
    backToAllUsers() {
      if (this.$route.query.backUrl) {
        location.href = this.$route.query.backUrl;
      } else {
        this.$router.push('/users');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__user {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 70px 10px 10px;
}
</style>
