var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home__link-cards-wrapper"},[_c('h2',{staticClass:"home__title mobile"},[_vm._v("ДОБРЕ ДОШЛИ В ПОРТАЛА НА ХОЛДИНГ КЦМ 2000")]),_c('div',{staticClass:"home__link-cards"},[_c('a',{staticClass:"home__link-card",attrs:{"href":_vm.config.otpuskiUrl}},[_vm._m(0),_vm._m(1)]),_c('a',{staticClass:"home__link-card",attrs:{"href":`${_vm.config.otpuskiUrl}/vacations-hourly`}},[_vm._m(2),_vm._m(3)]),(_vm.activeCompany !== 'КЦМ 2000')?_c('a',{staticClass:"home__link-card",attrs:{"href":`${_vm.config.otpuskiUrl}/schedule`}},[_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.activeCompany !== 'КЦМ 2000')?_c('a',{staticClass:"home__link-card",on:{"click":_vm.openTrdosie}},[_vm._m(6),_vm._m(7)]):_vm._e(),_vm._m(8),_vm._m(9),(_vm.currentUser._id ? _vm.currentUser.roles.admin || _vm.currentUser.roles.hr || _vm.currentUser.roles.supervisor : false)?_c('router-link',{staticClass:"home__link-card",attrs:{"to":"/users"}},[_c('header',[_c('img',{attrs:{"src":require("@/assets/img/home/color/users.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/white/users.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/mobile/m-users.svg")}})]),_c('p',[_vm._v("Контакти и локация на служителите в КЦМ. Настройки на"),_c('br'),_vm._v("профили и роли")])]):_vm._e(),_vm._m(10)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('img',{attrs:{"src":require("@/assets/img/home/color/vac.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/white/vac.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/mobile/m-vac.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Заявяване и одобряване на отпуск - годишен, неплатен,"),_c('br'),_vm._v("целеви, ученически, за баща и др.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('img',{attrs:{"src":require("@/assets/img/home/color/vac-h.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/white/vac-h.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/mobile/m-vac-h.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Заявяване и одобряване на почасови отсъствия, служебни"),_c('br'),_vm._v("бележки, компенсации и Home Office")])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('img',{attrs:{"src":require("@/assets/img/home/color/sch.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/white/sch.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/mobile/m-sch.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("График по бригади, личен график,"),_c('br'),_vm._v("отсъствия и Форми 76")])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('img',{attrs:{"src":require("@/assets/img/home/color/trd.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/white/trd.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/mobile/m-trd.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Лично електронно трудово досие, заявления,"),_c('br'),_vm._v("договори и споразумения")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home__link-card",attrs:{"href":"http://hrana.kcm.bg","target":"_blank"}},[_c('header',[_c('img',{attrs:{"src":require("@/assets/img/home/color/menu.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/white/menu.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/mobile/m-menu.svg")}})]),_c('p',[_vm._v("Преглед на менюто за седмицата")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home__link-card",attrs:{"href":"http://vkus.kcm","target":"_blank"}},[_c('header',[_c('img',{attrs:{"src":require("@/assets/img/home/color/vkus.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/white/vkus.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/mobile/m-vkus.svg")}})]),_c('p',[_vm._v("Новини и полезна информация от"),_c('br'),_vm._v("вътрешната мрежа на КЦМ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"home__link-card",attrs:{"href":"https://znanie.kcm.bg/","target":"_blank"}},[_c('header',[_c('img',{attrs:{"src":require("@/assets/img/home/color/eln.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/white/eln.svg")}}),_c('img',{attrs:{"src":require("@/assets/img/home/mobile/m-eln.svg")}})]),_c('p',[_vm._v("Електронна платформа за обучение на КЦМ")])])
}]

export { render, staticRenderFns }