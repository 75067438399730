<template>
  <div class="user-vacations" :class="{ 'user-vacations--2000': activeCompany === 'КЦМ 2000' }">
    <div class="tiny__content">
      <p class="tiny__content__title tiny__content__title--dropwdown">
        ВСИЧКИ ИЗПОЛЗВАНИ ПОЧАСОВИ ОТСЪСВИЯ ОТ СЛУЖИТЕЛЯ
        <Input v-model="selectedYear" :list="userYears" placeholder="Година" @input="getVacationsHourly()" />
      </p>
      <hr class="line" />
      <Table v-if="vacationsHourly.length">
        <thead>
          <tr>
            <th>№</th>
            <th>ДАТА</th>
            <th>ВИД ОТСЪСТВИЕ</th>
            <th>ДАТА/ПЕРИОД ОТСЪСТВИЕ</th>
            <th>ЧАС ОТ</th>
            <th>ЧАС ДО</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="v in vacationsHourly" :key="v._id" @click="openVacation(v._id)">
            <td>{{ v.number }}</td>
            <td>{{ v.dateOfSigning | formatDate }}</td>
            <td>{{ v.template[`typeName${v.type}`] }}</td>
            <template v-if="v.type === '0012' || v.type === '0016' || v.type === '0045' || v.type === '0046'">
              <td>
                <span>{{ v.dateFrom | formatDate }}</span>
                <span> - {{ v.dateTo | formatDate }}</span>
              </td>
              <td></td>
              <td></td>
            </template>
            <template v-else>
              <td>
                <span>{{ v.dateFrom | formatDate }}</span>
              </td>
              <td>{{ v.hourFrom }}</td>
              <td>{{ v.hourTo }}</td>
            </template>
          </tr>
        </tbody>
      </Table>
      <p v-else>Служителят все още няма добавени почасови отсъствия</p>
    </div>
    <Pagination :total="total" :size="size" />
  </div>
</template>

<script>
import Table from '@/components/Table';
import { mapGetters } from 'vuex';
import types from '@/data/vacation-types.json';
import config from '../../../config-public.json';
import Pagination from '../../components/Pagination.vue';
import Input from '../../components/inputs/Input.vue';
import { getYear, lastDayOfYear, startOfYear } from 'date-fns';
export default {
  components: {
    Table,
    Pagination,
    Input,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },

  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
  watch: {
    $route: {
      handler: 'getYears',
      immediate: true,
    },
  },
  data() {
    return {
      config,
      types,
      vacationsHourly: [],
      userYears: [],
      selectedYear: '',
      size: 30,
      total: 0,
    };
  },
  methods: {
    getVacationsHourly() {
      this.vacationsHourly = [];
      let url = `/users/${this.$route.params.id}/vacations-hourly?size=${this.size}&page=${
        this.$route.query.page || 1
      }`;

      if (this.selectedYear) {
        let start = lastDayOfYear(new Date(this.selectedYear, 1, 1));
        let end = startOfYear(new Date(this.selectedYear, 1, 1));

        start.setHours(12, 0, 0, 0);
        end.setHours(12, 0, 0, 0);
        url += `&dateFrom=${start}&dateTo=${end}`;
      }

      this.$apiService.get(url).then((res) => {
        this.vacationsHourly = res.data.vacationsHourly;
        this.total = res.data.total;
      });
    },
    getYears() {
      this.userYears = [];
      let url = `/users/${this.$route.params.id}/getYears/vacations?vacationsHourly=true`;

      this.$apiService.get(url).then((res) => {
        this.userYears = res.data;
      });
    },
    openVacation(v) {
      location.href = `${config.otpuskiUrl}/vacations-hourly/${v}`;
    },
  },
  mounted() {
    this.selectedYear = getYear(new Date());
    this.getVacationsHourly();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.user-vacations__count {
  align-items: center;
  background-color: $lightBlue;
  border-radius: 10px;
  color: #fff;
  display: flex;
  height: 66px;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem;
  padding-right: 1.75rem;
  &.user-vacations__count--union {
    background-color: #26c6da;
    margin-top: 0.5rem;
  }
  &.user-vacations__count--paid-student {
    background-color: #009458;
    margin-top: 0.5rem;
  }
  &.user-vacations__count--paid-graduation {
    background-color: #76aa1b;
    margin-top: 0.5rem;
  }
  &.user-vacations__count--unpaid {
    background-color: #ad1457;
    margin-top: 0.5rem;
  }
  p {
    align-items: center;
    display: flex;
    font-weight: 700;
    .icon {
      font-size: $px18;
      font-weight: 400;
      margin-right: 1rem;
      &.edit {
        cursor: pointer;
        font-size: 2rem;
        margin-right: 0.5rem;
        opacity: 0.5;
        @include transition($transition);
        & + input,
        & + .number {
          margin-left: 0.5rem;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    &:last-child {
      font-size: 42px;
      font-weight: 500;
    }
    input {
      background-color: transparent;
      border: 2px dashed #fff;
      border-radius: 10px;
      color: #fff;
      font-size: 42px;
      outline: none;
      text-align: center;
      width: 90px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
}
.user-vacations--2000 {
  .user-vacations__count {
    background-color: #35aadf;
    &.user-vacations__count--paid-student {
      background-color: #76aa1b;
    }
    &.user-vacations__count--paid-graduation {
      background-color: #76aa1b;
    }
    &.user-vacations__count--unpaid {
      background-color: #a50101;
    }
  }
}
</style>
