<template>
  <div class="user-permissions">
    <div class="tiny__content">
      <p class="tiny__content__title">ЛИЧНИ ПРЕДПАЗНИ СРЕДСТВА</p>
      <hr class="line" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>
