<template>
  <div class="home__link-cards-wrapper">
    <h2 class="home__title mobile">ДОБРЕ ДОШЛИ В ПОРТАЛА НА ХОЛДИНГ КЦМ 2000</h2>
    <div class="home__link-cards">
      <a :href="config.otpuskiUrl" class="home__link-card">
        <header>
          <img src="@/assets/img/home/color/vac.svg" />
          <img src="@/assets/img/home/white/vac.svg" />
          <img src="@/assets/img/home/mobile/m-vac.svg" />
        </header>
        <p>Заявяване и одобряване на отпуск - годишен, неплатен,<br />целеви, ученически, за баща и др.</p>
      </a>
      <a :href="`${config.otpuskiUrl}/vacations-hourly`" class="home__link-card">
        <header>
          <img src="@/assets/img/home/color/vac-h.svg" />
          <img src="@/assets/img/home/white/vac-h.svg" />
          <img src="@/assets/img/home/mobile/m-vac-h.svg" />
        </header>
        <p>Заявяване и одобряване на почасови отсъствия, служебни<br />бележки, компенсации и Home Office</p>
      </a>
      <a :href="`${config.otpuskiUrl}/schedule`" class="home__link-card" v-if="activeCompany !== 'КЦМ 2000'">
        <header>
          <img src="@/assets/img/home/color/sch.svg" />
          <img src="@/assets/img/home/white/sch.svg" />
          <img src="@/assets/img/home/mobile/m-sch.svg" />
        </header>

        <p>График по бригади, личен график,<br />отсъствия и Форми 76</p>
      </a>
      <!-- <a :href="config.trdosieUrl" class="home__link-card"> -->
      <a class="home__link-card" @click="openTrdosie" v-if="activeCompany !== 'КЦМ 2000'">
        <header>
          <img src="@/assets/img/home/color/trd.svg" />
          <img src="@/assets/img/home/white/trd.svg" />
          <img src="@/assets/img/home/mobile/m-trd.svg" />
        </header>
        <p>Лично електронно трудово досие, заявления,<br />договори и споразумения</p>
      </a>

      <a href="http://hrana.kcm.bg" target="_blank" class="home__link-card">
        <header>
          <img src="@/assets/img/home/color/menu.svg" />
          <img src="@/assets/img/home/white/menu.svg" />
          <img src="@/assets/img/home/mobile/m-menu.svg" />
        </header>
        <p>Преглед на менюто за седмицата</p>
      </a>
      <a href="http://vkus.kcm" target="_blank" class="home__link-card">
        <header>
          <img src="@/assets/img/home/color/vkus.svg" />
          <img src="@/assets/img/home/white/vkus.svg" />
          <img src="@/assets/img/home/mobile/m-vkus.svg" />
        </header>
        <p>Новини и полезна информация от<br />вътрешната мрежа на КЦМ</p>
      </a>
      <router-link
        to="/users"
        class="home__link-card"
        v-if="currentUser._id ? currentUser.roles.admin || currentUser.roles.hr || currentUser.roles.supervisor : false"
      >
        <header>
          <img src="@/assets/img/home/color/users.svg" />
          <img src="@/assets/img/home/white/users.svg" />
          <img src="@/assets/img/home/mobile/m-users.svg" />
        </header>
        <p>Контакти и локация на служителите в КЦМ. Настройки на<br />профили и роли</p>
      </router-link>
      <a href="https://znanie.kcm.bg/" target="_blank" class="home__link-card">
        <header>
          <img src="@/assets/img/home/color/eln.svg" />
          <img src="@/assets/img/home/white/eln.svg" />
          <img src="@/assets/img/home/mobile/m-eln.svg" />
        </header>
        <p>Електронна платформа за обучение на КЦМ</p>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../../config-public.json';
export default {
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated', 'activeCompany']),
  },
  data() {
    return {
      config,
    };
  },
  methods: {
    openTrdosie() {
      this.$store.dispatch('setTwoFAMode', { state: true, url: config.trdosieUrl });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.home__link-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 2rem 2rem;
  width: 100%;
}

.home__link-card {
  align-items: flex-start;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 99px #00000029;
  border-radius: 10px;
  color: #000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $px24;
  padding-top: calc(#{$px24} * 2);
  position: relative;
  width: calc(25% - (6rem / 4));
  @include transition($transition);
  @include noselect();
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4vw;
    position: relative;
    width: 100%;
    z-index: 1;
    img {
      height: 5rem;
      max-width: 100%;
      @include transition($transition);

      &:nth-child(2) {
        opacity: 0;
        position: absolute;
        z-index: 1;
      }
      &:nth-child(3) {
        display: none;
      }
    }
  }
  p {
    position: relative;
    z-index: 1;
    @include transition($transition);
  }
  &::before {
    background-color: rgba(53, 170, 223, 0.8);
    border-radius: inherit;
    bottom: 0;
    content: '';
    height: 0;
    left: 0;
    width: 100%;
    position: absolute;
    @include transition($transition);
  }
  &:hover {
    header {
      img {
        opacity: 0;
        &:nth-child(2) {
          opacity: 1;
        }
      }
    }
    p.title,
    p.accent {
      color: #fff;
    }
    &::before {
      height: 100%;
    }
  }
}

@media screen and (max-width: $xl) {
  .home__link-card {
    header {
      .icon {
        font-size: 2.5rem;
      }
    }

    p {
      &.title {
        font-size: 1.81818rem;
        line-height: 1.81818rem;
      }
    }
  }
}

@media screen and (max-width: $l) {
  .home__link-card {
    header {
      margin-bottom: 2vw;
    }

    p {
      &.title {
        font-size: 1.6rem;
        line-height: 1.6rem;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .home__link-cards {
    gap: 1rem;
  }
  .home__link-card {
    gap: 15px;
    padding: 15px;
    width: calc(50% - 0.5rem);
    header {
      img {
        opacity: 1;
        &:nth-child(1),
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          display: block;
        }
      }
    }
    p {
      font-size: $px11;
    }
    &:hover {
      header {
        img {
          opacity: 1;
        }
      }
      p.title,
      p.accent {
        color: initial;
      }
      &::before {
        height: 0%;
      }
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .home__link-cards {
    margin-top: 1rem;
    padding: 0.5rem;
  }
  .home__link-card {
    margin: 0;
    width: calc(50% - 0.5rem);
    header {
      img {
        height: 3rem;
      }
    }
  }
}

@media screen and (max-width: $xxs) {
  .home__link-card {
    margin: 0 1rem 1rem;
    width: calc(100% - 2rem);
  }
}
</style>