<template>
  <div class="sidepanel__2fa">
    <div>
      <transition name="appear" appear>
        <img src="@/assets/img/sidepanel/trd-logo-light.svg" alt="KCM 2000 ePortal" class="sidepanel__2fa__logo" />
      </transition>
    </div>
    <div class="lock">
      <img src="@/assets/img/sidepanel/lock_animated.svg" />
      <p>Достъпът до Трудовото досие става след повторно потвърждаване на самоличността.</p>
    </div>
    <main>
      <form>
        <p class="info">
          Като допълнителна мярка за сигурност, ще трябва да въведете кода, който изпратихме на мейл, посочен от Вас
        </p>
        <input
          type="text"
          inputmode="numeric"
          placeholder="Въведете кода тук"
          v-model="code"
          @input="error = ''"
          v-focus
        />
        <button @click.prevent="enter" :disabled="!code || disabled">ВХОД В ТРУДОВИТЕ ДОСИЕТА</button>
        <p class="error">
          <transition name="fade">
            <span v-if="error">{{ error }}</span>
          </transition>
        </p>
      </form>
      <footer>
        <a class="new-code" @click="sendToken">ИЗПРАТЕТЕ НОВ КОД</a>
      </footer>
    </main>
  </div>
</template>

<script>
import config from '../../../config-public.json';
import { mapGetters } from 'vuex';
export default {
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'stayInMyKcm']),
  },
  data() {
    return {
      config,
      code: '',
      error: '',
      disabled: false,
    };
  },
  methods: {
    sendToken() {
      if (!this.currentUser.email) {
        this.disabled = true;
        this.$snotify.warning('За да получите код първо трябва да добавите e-mail адрес в профила си');
        return;
      }
      this.$apiService.post('/2fa/send-token').catch((err) => {
        console.log(err);
      });
    },
    enter() {
      this.$apiService
        .post(`/2fa/${this.code}`)
        .then((res) => {
          if (res.data.success) {
            this.forward();
          } else {
            if (res.data.refresh) location.reload();
            this.error = res.data.message;
          }
        })
        .catch((err) => {
          // this.error = err.response.data.message;
          console.log(err);
        });
    },
    forward() {
      if(this.stayInMyKcm && this.$route.params.id) {
        this.$store.dispatch('setTwoFAMode', { state: false });
        this.$router.push(`/users/${this.$route.params.id}/trdoc`)
      } else {
        location.href = this.$route.query.openDocument ? `${this.config.trdosieUrl}/?openDocument=${this.$route.query.openDocument}` : this.config.trdosieUrl;
      }
    },
  },
  mounted() {
    this.sendToken();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__2fa {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 22px;
  position: relative;
  width: 100%;
}

.sidepanel__2fa__logo {
  width: 100%;
}
.lock {
  color: #35aadf;
  text-align: center;
  p {
    margin-top: 1rem;
    padding: 0 1rem;
  }
}
main {
  width: 100%;
  form {
    .info {
      text-align: center;
      margin-bottom: 20px;
    }
    input,
    button {
      border-radius: 24px;
      border: 0;
      height: 48px;
      outline: none;
      width: 100%;
    }

    input {
      display: block;
      font-size: $px14;
      font-weight: 500;
      margin-bottom: 20px;
      padding: 0 20px;
      &::placeholder {
        color: rgba($color: $mainColor, $alpha: 0.5);
      }
    }

    button {
      align-items: center;
      background-color: $darkBlue;
      color: #fff;
      cursor: pointer;
      font-size: $px12;
      font-weight: 700;
      display: flex;
      justify-content: center;
      @include transition($transition);
      &:disabled {
        cursor: default;
        opacity: 0.5;
      }
      &:not(:disabled):hover {
        background-color: rgba($color: $darkBlue, $alpha: 0.8);
      }
    }
  }
}

.error {
  line-height: 1rem;
  min-height: 1rem;
  margin: 1rem 0 0.5rem;
  text-align: center;
}
footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $px10;

  .new-code {
    color: #35aadf;
    text-decoration: underline;
    margin-bottom: 5px;
    @include hover();
  }
}
</style>