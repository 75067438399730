<template>
  <div class="user-permissions" v-if="user._id">
    <div class="tiny__content">
      <p class="tiny__content__title">ДОСТЪП</p>
      <hr class="line" />
      <!-- <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch v-model="user.inactive" />
        <div class="texts">
          <p>Неактивен служител без право на вход</p>
          <p>Временна забрана за вход.</p>
        </div>
      </div> -->
      <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch v-model="user.fired" />
        <div class="texts">
          <p>Неактивен служител</p>
          <p>
            Отбелязва се, ако служителят вече не работи за КЦМ. Ще продължи да
            се вижда в старите справки, но няма да се вижда при пускане на
            отпуски.
          </p>
        </div>
      </div>
      <p class="tiny__content__title">РОЛИ</p>
      <hr class="line" />
      <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch v-model="user.roles.admin" />
        <div class="texts">
          <p>Администратор</p>
          <p>
            Права да вижда и променя всички потребители и създава нови. Може да
            променя ролите на останалите потребители. Вижда всички справки и
            активности. Достъп до всички Настройки
          </p>
        </div>
      </div>
      <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch v-model="user.roles.hr" />
        <div class="texts">
          <p>Човешки ресурси</p>
          <p>
            Подписва и одобрява отпуските на финалния етап. Достъп до Настройки
          </p>
        </div>
      </div>
      <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch v-model="user.roles.supervisor" />
        <div class="texts">
          <p>Ръководител</p>
          <p>Одобрява отпуски на служителите. Достъп до Настройки</p>
        </div>
      </div>
      <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch v-model="user.roles.employee" />
        <div class="texts">
          <p>Служител</p>
          <p>
            Вижда календара и останалите служители. Може да пуска отпуски за
            себе си
          </p>
        </div>
      </div>
      <!-- <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch v-model="user.roles.host" />
        <div class="texts">
          <p>Домакин</p>
          <p>Организира и контролира поръчването и отпускането на ЛПС</p>
        </div>
      </div>
      <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch v-model="user.roles.accountant" />
        <div class="texts">
          <p>Счетоводител</p>
          <p>Роля свързана с ЛПС</p>
        </div>
      </div> -->
      <div
        class="user__checkbox-switch-wrapper"
        v-if="activeCompany === 'КЦМ АД'"
      >
        <CheckboxSwitch v-model="user.roles.f76" />
        <div class="texts">
          <p>Служител справки по Форма 76</p>
          <p>Роля свързана с Форма 76</p>
        </div>
      </div>
      <hr class="line" />
      <p class="tiny__content__title">КОМПАНИИ</p>
      <hr class="line" />
      <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch
          :value="user.companies.indexOf('КЦМ АД') > -1"
          @input="toggleCompany('КЦМ АД')"
        />
        <div class="texts">
          <p>КЦМ АД</p>
          <p v-if="user.company === 'КЦМ АД'">Основна компания</p>
        </div>
      </div>
      <div class="user__checkbox-switch-wrapper">
        <CheckboxSwitch
          :value="user.companies.indexOf('КЦМ 2000') > -1"
          @input="toggleCompany('КЦМ 2000')"
        />
        <div class="texts">
          <p>КЦМ 2000</p>
          <p v-if="user.company === 'КЦМ 2000'">Основна компания</p>
        </div>
      </div>
      <div class="tiny__content__buttons">
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button @click="cancel">Откажи</Button>
        <Button class="button--blue" @click="save" :disabled="loading"
          >Запиши</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckboxSwitch from '@/components/inputs/CheckboxSwitch';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
export default {
  components: {
    CheckboxSwitch,
    Button,
    Loading,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },
  data() {
    return {
      loading: false,
      success: undefined,
      user: {},
    };
  },
  methods: {
    cancel() {
      this.$router.push(`/users/${this.$route.params.id}`);
    },
    getUser() {
      this.$apiService.get(`/users/${this.$route.params.id}`).then((res) => {
        this.user = res.data;
      });
    },
    toggleCompany(c) {
      const i = this.user.companies.indexOf(c);
      if (i > -1) {
        this.user.companies.splice(i, 1);
      } else {
        this.user.companies.push(c);
      }
    },
    save() {
      if (!this.user.companies.length) {
        return this.$snotify.error(
          'Потребителят трябва да участва поне в една компания'
        );
      }
      this.loading = true;
      this.$apiService
        .put(`/users/access/${this.$route.params.id}`, this.user)
        .then(() => {
          this.success = true;
        })
        .catch((err) => {
          this.success = false;
          console.log(err);
        })
        .then(() => {
          setTimeout(() => {
            // after successfull save if user is editing his own profile do this checks
            if (this.success && this.user._id === this.currentUser._id) {
              if (!this.user.roles.admin) {
                this.cancel();
              }
              // if (this.user.inactive || this.user.fired) {
              if (this.user.fired) {
                this.$router.push('/');
              }
            }

            this.loading = false;
            this.success = undefined;
          }, 1000);
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
