<template>
  <div class="user-avatar" :class="{ big }">
    <img v-if="user.avatar" :src="`${uploads}/avatars/${user.avatar}`" />
    <span v-else>{{ firstLastLetters }}</span>
  </div>
</template>

<script>
export default {
  props: ['user', 'big'],
  computed: {
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
    firstLastLetters() {
      const fullName = this.user.name.split(' ');
      const first = fullName[0].split('')[0];
      const last = fullName.length > 1 ? fullName[fullName.length - 1].split('')[0] : '';

      return `${first + last}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.user-avatar {
  display: flex;
  img,
  span {
    align-items: center;
    background: #292d32;
    border-radius: 50%;
    border: 1px solid #35aadf;
    color: #fff;
    display: flex;
    height: 30px;
    justify-content: center;
    object-fit: cover;
    width: 30px;
  }
  &.big {
    img,
    span {
      height: 50px;
      font-size: 18px;
      width: 50px;
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .user-avatar {
    img,
    span {
      height: 50px;
      font-size: 18px;
      width: 50px;
    }
  }
}
</style>