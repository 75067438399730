import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Help from '@/views/Help.vue'
import store from '@/store/index';
import users from './users';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/help',
    name: "help",
    component: Help,
    meta: {
      requiresNoAuth: true
    },
  },
  {
    path: '/styleguide',
    name: 'styleguide',
    component: () => import('@/views/Styleguide.vue'),
  },
  ...users,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch('checkAuth').then(() => {
    
    if (!store.getters.isSidePanelOpen) {
      store.dispatch('toggleSidePanel');
    }
    
    // inactive or fired
    // if (store.getters.currentUser.inactive || store.getters.currentUser.fired) {
    if (store.getters.currentUser.fired) {
      store.dispatch('logout').then(() => {
        return next({
          path: '/'
        });
      });
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters.isAuthenticated) {
        if (store.getters.currentUser.companies.indexOf(store.getters.activeCompany) < 0) {
          Vue.$snotify.warning(`Нямате достъп до страниците от ${store.getters.activeCompany}`);
          return next({ path: '/' });
        }

        // if only specific roles are allowed
        if (to.meta.rolesAllowed) {
          let match = false;
          for (let r of to.meta.rolesAllowed) {
            if (store.getters.currentUser.roles[r]) {
              match = true;
              break;
            }
          }
          if (!match) {
            return next({
              path: from.path || '/'
            });
          }
        }
        if (to.meta.companiesAllowed) {
          if (to.meta.companiesAllowed.indexOf(store.getters.activeCompany) < 0) {
            return next({
              path: from.path || '/'
            });
          }
        }
        // no role required
        return next();
      } else if (!store.getters.isSidePanelOpen) {
        store.dispatch('toggleSidePanel');
      }
      next({
        path: '/'
      });
    } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
      
      store.dispatch('toggleSidePanel');
      return next();

    } else {
      next();
    }
  });
});

export default router;
